import { useState } from "react";
import { ValuesOf, Request } from "../../../../utils";

type addTaskData = {
  [key in ValuesOf<typeof addTaskFormFields>]: string;
};

const { API_URL } = window;

const addTaskFormFields = <const>[
  "batch_size",
  "markup_type",
  "name",
  "settings",
];

const useTaskRequests = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("access_token");

  const dropErrors = () => {
    setErrors([]);
  };

  const request = new Request(setErrors, setLoading);

  const addTaskRequest = (data: addTaskData) => {
    return request.create({
      method: "POST",
      url: `${API_URL}/tasks`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const deleteTaskRequest = (id: number) => {
    return request.create({
      method: "DELETE",
      url: `${API_URL}/tasks/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const updateTaskRequest = (id: number, data: { [key: string]: string }) => {
    return request.create({
      method: "PATCH",
      url: `${API_URL}/tasks/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getTasksRequest = () => {
    return request.create({
      method: "GET",
      url: `${API_URL}/tasks`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return {
    errors,
    loading,
    addTaskRequest,
    dropErrors,
    deleteTaskRequest,
    updateTaskRequest,
    getTasksRequest,
  };
};

export default useTaskRequests;
