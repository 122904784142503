import { createAction } from "redux-actions";

export const changeTimetrackingUsers = createAction(
  "CHANGE_TIMETRACKING_USERS"
);

export const changeTimetrackingTasks = createAction(
  "CHANGE_TIMETRACKING_TASKS"
);

export const dropTimetracking = createAction("DROP_TIMETRACKING");

export const changeTimetrackingDate = createAction("CHANGE_TIMETRACKING_DATE");

export const changeTimetrackingThreshold = createAction(
  "CHANGE_TIMETRACKING_THRESHOLD"
);

export const setTimeReportData = createAction("SET_TIME_REPORT_DATA");

export const setAvailableItems = createAction("SET_AVAILABLE_ITEMS");

export const setNameDict = createAction("SET_NAME_DICT");
