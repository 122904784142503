import BatchDissociatedBanner from "./BatchDissociatedBanner";
import ImageNotFoundBanner from "./ImageNotFoundBanner";
import NoMoreBatchesBanner from "./NoMoreBatchesBanner";

const products = {
  BATCH_DISSOCIATED: BatchDissociatedBanner,
  IMAGE_NOT_FOUND: ImageNotFoundBanner,
  NO_MORE_BATCHES: NoMoreBatchesBanner,
};

const createBanner = (type) => {
  // check invalid type passsing
  if (!(type in products)) {
    return null;
  }

  // create existing components
  return products[type];
};

export default {
  createBanner,
};
