import React, { useEffect, useState } from "react";

export default function Img(props) {
  const [style, setStyle] = useState(null);

  useEffect(() => {
    const isWide = new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve(image.width > image.height);
      };
      image.src = props.src;
    });
    isWide.then((wide) => {
      let newStyle = null;
      if (wide) {
        newStyle = {
          minWidth: "20vw",
          maxWidth: "100%",
          maxHeight: "65vh",
        };
      } else {
        newStyle = {
          minWidth: "20vw",
          minHeight: "20vh",
          maxHeight: "65vh",
        };
      }
      setStyle(newStyle);
    });
  }, [props.src]);

  return style !== null ? (
    <img src={props.src} style={style} alt="Изображение не найдено" />
  ) : null;
}
