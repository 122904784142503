import React from "react";
import { useSelector } from "react-redux";
import { timetrackingSelector } from "../../../../selectors";

const formatThreshold = (threshold) => {
  return threshold.replace("s", "с").replace("m", "м ").replace("h", "ч ");
};

const ThresholdsList = () => {
  const { timeReportData, nameDictionary, timeThreshold } =
    useSelector(timetrackingSelector);
  const { user_thresholds } = timeReportData;
  if (user_thresholds && !timeThreshold) {
    return (
      <>
        <p>Пороги, выше которых 5% времен выполнения батчей пользователей:</p>
        <ul>
          {Object.entries(user_thresholds).map(([userId, threshold]) => (
            <li>
              {nameDictionary.users[userId]}: {formatThreshold(threshold)}
            </li>
          ))}
        </ul>
      </>
    );
  }
  return null;
};

export default ThresholdsList;
