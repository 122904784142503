import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import "react-infinite-calendar/styles.css";
import { loadAnalytics } from "../../../../../actions/adminPanel";
import {
  changeTimetrackingUsers,
  changeTimetrackingTasks,
  changeTimetrackingThreshold,
} from "../../../../../actions/timetracking";
import DatePicker from "./DatePicker";
import DropQueryButton from "../../common/DropQueryButton";
import TimetrackingUserSelector from "./TimetrackingUserSelector";
import TimetrackingTaskSelector from "./TimetrackingTaskSelector";
import styles from "./style.module.css";
import useTimetracking from "./useTimetracking";
import { Button, CircularProgress as Loader } from "@mui/material";

const Wrapper = styled("div")({
  display: "flex",
  padding: "10px 0",
  width: "100%",
  flexWrap: "wrap",
});

const timetrackingQuerySelector = (state) =>
  state.adminPanelReducer.timetrackingQuery;

const convertTimeThreshold = (time) => {
  const resultTime = [];
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  if (minutes !== 0) {
    resultTime.push(minutes + " м");
  }
  if (seconds !== 0 || time === 0) {
    resultTime.push(seconds + " c");
  }
  return resultTime.join(" ");
};

const TimetrackingQuerySelector = () => {
  const { timeThreshold, from, to } = useSelector(
    (state) => state.timetrackingReducer
  );
  const { taskId, userId, minThreshold, maxThreshold } = useSelector(
    timetrackingQuerySelector
  );

  const timetrackingData = useSelector(
    (state) => state.adminPanelReducer.timetrackingData
  );

  let viewTimeThreshold = timeThreshold;
  if (timeThreshold === null && timetrackingData !== null) {
    viewTimeThreshold = timetrackingData.avg_threshold;
  }

  const {
    dispatch,
    selectedTasks,
    assignedTasks,
    selectedUsers,
    assignedUsers,
    handleDateChange,
    availableDates,
    dropFilters,
    disableGetReport,
    getTimeReport,
    showLoader,
  } = useTimetracking();

  useEffect(() => {
    if (userId !== null && taskId !== null && from !== null && to !== null) {
      dispatch(loadAnalytics());
    }
  }, [userId, taskId, from, to, timeThreshold, dispatch]);

  return (
    <Wrapper>
      <TimetrackingUserSelector
        selectedUsers={selectedUsers}
        assignedUsers={assignedUsers}
        handleIdChange={(e, value) => {
          dispatch(changeTimetrackingUsers(value));
        }}
      />
      <TimetrackingTaskSelector
        selectedTasks={selectedTasks}
        assignedTasks={assignedTasks}
        handleIdChange={(e, value) => {
          dispatch(changeTimetrackingTasks(value));
        }}
      />
      <div style={{ width: 250, fontSize: 14, marginRight: 20 }}>
        <DatePicker
          handleDateChange={handleDateChange}
          availableDates={availableDates}
          enableAllDates={!selectedUsers.length && !selectedTasks.length}
        />
        <input
          className={`${styles["threshold-input"]}`}
          type="range"
          onMouseUp={(e) =>
            dispatch(changeTimetrackingThreshold(+e.target.value))
          }
          min={minThreshold}
          max={maxThreshold}
        />
        <p>
          {viewTimeThreshold !== null
            ? `Порог отсечения: ${convertTimeThreshold(viewTimeThreshold)}`
            : "Порог отсечения не настроен"}
        </p>
      </div>
      <DropQueryButton handleClick={dropFilters} />
      <Button
        onClick={getTimeReport}
        variant="contained"
        disabled={disableGetReport}
        sx={{ mx: 2.5, height: 56 }}
      >
        Получить отчет
      </Button>
      {showLoader && <Loader sx={{ m: 1 }} />}
    </Wrapper>
  );
};

export default TimetrackingQuerySelector;
