import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TASK_MARKUP_TYPE } from "../../../Tasks";
import TaskClassesCreateList from "./TaskClassesCreateList";
import ClassesListContext from "./taskClassesListContext";
import useTaskRequests from "./useTaskRequests";
import { adminPanelSelector } from "../../selectors";

const initialState = {
  name: null,
  markup_type: null,
  batch_size: "",
  settings: null,
};

const placeholders = {
  name: "Введите название",
  markup_type: "Укажите тип разметчика",
  batch_size: "Введите размер батча",
};

export default function TaskCreateModal(props) {
  const [formData, setFormData] = useState(initialState);
  const { addTaskRequest, loading, errors, dropErrors } = useTaskRequests();
  const { tasks } = useSelector(adminPanelSelector);

  const dispatch = useDispatch();

  const handleCreate = async (e) => {
    e.preventDefault();
    const newTask = await addTaskRequest(formData);
    if (!newTask) return;
    dispatch({
      type: "UPDATE_TASKS",
      payload: { tasks: [...tasks, newTask], task: newTask },
    });
    setFormData(initialState);
    props.createHook(false);
  };

  const updateFormField = (key, value) => {
    const newFormData = Object.assign({}, formData);
    newFormData[key] = value;
    setFormData(newFormData);
  };

  const handleHide = () => {
    setFormData(initialState);
    dropErrors();
    props.createHook(false);
  };

  const createViewItem = (item) => {
    const newFormData = Object.assign({}, formData);
    newFormData.settings.classes.push(item);
    setFormData(newFormData);
  };

  const deleteViewItem = (id) => {
    const newFormData = Object.assign({}, formData);
    newFormData.settings.classes.splice(id, 1);
    setFormData(newFormData);
  };

  const clsListContValue = {
    settings: formData.settings,
    handleCreate: createViewItem,
    handleDelete: deleteViewItem,
  };

  const handleMarkupTypeChange = (value) => {
    let batchSize = "";
    const markupTypeData = TASK_MARKUP_TYPE[value];
    if ("batchSize" in markupTypeData) {
      batchSize = markupTypeData.batchSize;
    }
    const newFormData = Object.assign({}, formData);
    newFormData.batch_size = batchSize;
    newFormData.markup_type = value;
    setFormData(newFormData);
  };

  let markupTypeData = null;
  if (formData.markup_type !== null) {
    markupTypeData = TASK_MARKUP_TYPE[formData.markup_type];
  }

  let batchSizeDisabled = true;
  let batchSizePlaceholder = "Зависит от выбранного разметчика";
  if (markupTypeData !== null) {
    batchSizeDisabled = "batchSize" in markupTypeData;
    if (batchSizeDisabled) {
      batchSizePlaceholder = markupTypeData.batchSize;
      if (markupTypeData.batchSize === null) {
        batchSizePlaceholder = "Варьируется";
      }
    } else {
      batchSizePlaceholder = placeholders.batch_size;
    }
    if (markupTypeData.editableView && formData.settings === null) {
      const newFormData = Object.assign({}, formData);
      newFormData.settings = {
        classes: [],
      };
      setFormData(newFormData);
    }
  }

  return (
    <Modal show={props.show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Создание нового задания</Modal.Title>
      </Modal.Header>

      <Form className="mb-3">
        <Modal.Body>
          <Alert show={errors.length} variant="danger">
            {errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </Alert>
          <Form.Control
            className="mb-3"
            value={formData.name}
            onChange={(e) => updateFormField("name", e.target.value)}
            isInvalid={!formData.name}
            placeholder={placeholders.name}
          />
          <Form.Control
            as="select"
            className="mb-3"
            onChange={(e) => handleMarkupTypeChange(e.target.value)}
            isInvalid={!formData.markup_type}
          >
            <option value="" disabled selected>
              Выберите тип разметчика
            </option>

            {Object.entries(TASK_MARKUP_TYPE).map((key, value) => {
              const [markupValue, listItem] = key;
              const { name } = listItem;
              return (
                <option value={markupValue} key={markupValue}>
                  {name}
                </option>
              );
            })}
          </Form.Control>
          <Row>
            <Col className="text-center mt-2 col-4 col-sm-4 col-md-4 col-lg-4">
              Размер батча
            </Col>
            <Col>
              <Form.Control
                className="mb-3"
                disabled={batchSizeDisabled}
                value={formData.batch_size === null ? "" : formData.batch_size}
                onChange={(e) => updateFormField("batch_size", e.target.value)}
                placeholder={batchSizePlaceholder}
              />
            </Col>
          </Row>
          {markupTypeData !== null && markupTypeData.editableView ? (
            <ClassesListContext.Provider value={clsListContValue}>
              <TaskClassesCreateList />
            </ClassesListContext.Provider>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="success"
            disabled={loading || !formData.name || !formData.markup_type}
            onClick={handleCreate}
          >
            Создать
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
