import React from "react";
import { Form } from "react-bootstrap";

type Task = {
  id: number;
  markup_type: string;
  state: string;
};

const MarkedSelector = ({ data, handleChange }: any) => {
  const { tasks, taskId, marked } = data;
  if (!taskId) return null;

  const selectedTask = (tasks as Task[]).filter((t) => t.id === taskId)[0];

  if (selectedTask.markup_type !== "binary") return null;

  return (
    <Form.Control as="select" className="mb-3" onChange={handleChange}>
      <option value="" disabled selected={marked === null}>
        Выберите ответ
      </option>
      <option selected={marked === "true"} value={"true"}>
        Да
      </option>
      <option selected={marked === "false"} value={"false"}>
        Нет
      </option>
    </Form.Control>
  );
};

export default MarkedSelector;
