import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";

const Logout = () => {
  const [isLogOut, setIsLogOut] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("access_token");
    dispatch({
      type: "USER_LOGOUT",
    });
    setIsLogOut(false);
  }, [dispatch]);

  return !isLogOut ? <Redirect to="/login" /> : null;
};

export default Logout;
