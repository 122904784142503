import React, { useState } from "react";
import { useSelector } from "react-redux";
import { timetrackingSelector } from "../../../../selectors";
import { DefaultViewDivider } from "./Divider";
import DefaultViewBlock from "./DefaultViewBlock";
import DefaultViewCommon from "./DefaultViewCommon";
import { parseTimeReport, convertToArray } from "./utils";
import NothingToShowBanner from "../../NothingToShowBanner";

const DefaultView = () => {
  const { timeReportData, nameDictionary } = useSelector(timetrackingSelector);
  const [divideBy, setDivideBy] = useState("users");
  const { dates } = timeReportData;
  let users = {};
  let tasks = {};
  let usersArray = [];
  let tasksArray = [];
  if (dates) {
    const { usersObj, tasksObj } = parseTimeReport(dates);
    users = usersObj;
    tasks = tasksObj;
    usersArray = convertToArray(users);
    tasksArray = convertToArray(tasks);
  }

  let currentArrayToMap = divideBy === "users" ? usersArray : tasksArray;
  const names =
    divideBy === "users" ? nameDictionary.users : nameDictionary.tasks;
  if (!dates) {
    return <NothingToShowBanner />;
  }
  return (
    <>
      <DefaultViewDivider divideBy={divideBy} handleChange={setDivideBy} />
      <DefaultViewCommon
        tasks={tasksArray}
        users={usersArray}
        nameDict={nameDictionary}
      />
      {currentArrayToMap.map((item) => (
        <DefaultViewBlock key={item.id} item={item} names={names} />
      ))}
    </>
  );
};

export default DefaultView;
