import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import styles from "./text-task.module.css";

const HotkeyEnum = {
  save: 13,
  prev: 37,
  skip: 40,
};

export default function TextTaskButtons({
  batchImages,
  disabled,
  handleHistoryBackButtonClick,
  handleSave,
  batchId,
  handleSkip,
}) {
  const dispatch = useDispatch();

  const saveButton = useRef();
  const skipButton = useRef();
  const inputField = useRef();

  useEffect(() => {
    const handleHotkeys = (e) => {
      if (e.ctrlKey) {
        switch (e.keyCode) {
          case HotkeyEnum.skip:
            skipButton.current.click();
            return;
          case HotkeyEnum.prev:
            handleHistoryBackButtonClick();
            return;
          default:
            break;
        }
      }
      switch (e.keyCode) {
        case HotkeyEnum.save:
          saveButton.current.click();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleHotkeys);
    return () => {
      window.removeEventListener("keydown", handleHotkeys);
    };
  }, [handleHistoryBackButtonClick]);

  useEffect(() => {
    if (!disabled) {
      inputField.current.focus();
    }
  }, [disabled]);

  useEffect(() => {
    if (batchImages === null) {
      return;
    }
    const markup = batchImages[0].markup;
    if (markup === null) {
      dispatch({
        type: "SET_MARKUP",
        imageId: batchImages[0].id,
        markup: {
          text: "",
        },
      });
    }
  }, [batchId]);

  const handleInput = async (e) => {
    const imageId = batchImages[0].id;
    const markup = {
      text: e.target.value,
    };
    dispatch({
      type: "SET_MARKUP",
      imageId: imageId,
      markup: markup,
    });
  };

  let defaultText = null;
  if (batchImages !== null) {
    if (batchImages[0].markup !== null) {
      defaultText = batchImages[0].markup.text;
    }
  }

  return (
    <div>
      <Row>
        <Container className="mt-3">
          <Form.Control
            ref={inputField}
            disabled={disabled}
            className={styles["batch-text-markup"]}
            type="input"
            value={defaultText}
            style={{ width: "60%", marginLeft: "20%" }}
            onChange={(e) => handleInput(e)}
          />
        </Container>
      </Row>
      <Row className="mb-0 mt-3">
        <Col>
          <div className={styles["button-container"]}>
            <div className={styles["button-hint"]}>Ctrl+◄</div>
            <Button
              disabled={disabled}
              ref={skipButton}
              variant="outline-info"
              onClick={handleHistoryBackButtonClick}
            >
              Prev
            </Button>
          </div>
        </Col>
        <Col>
          <div className={styles["button-container"]}>
            <div className={styles["button-hint"]}>Ctrl+▼</div>
            <Button
              disabled={disabled}
              ref={skipButton}
              variant="outline-info"
              onClick={handleSkip}
            >
              Skip
            </Button>
          </div>
        </Col>
        <Col>
          <div className={styles["button-container"]}>
            <div className={styles["button-hint"]}>Enter</div>
            <Button
              disabled={disabled || !defaultText?.trim().length}
              ref={saveButton}
              variant="outline-info"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
