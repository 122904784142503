import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatchImages } from "../../../../../actions/adminPanel";
import styles from "./style.module.css";
import TaskFactory from "../../../../Tasks";
import { adminPanelSelector } from "../../../selectors";
import { markupQuerySelector } from "../SideBar/selectors";
import DeleteMarkupButton from "./DeleteMarkupButton";
import { taskDataSelector } from "./selectors";

const Viewer = (props) => {
  const { batchId } = useSelector(markupQuerySelector);
  const { images, batches } = useSelector(adminPanelSelector);
  const taskData = useSelector(taskDataSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (images === null && batches !== null) {
      dispatch(getBatchImages(batchId));
    }
  }, [images, batches, batchId, dispatch]);

  if (taskData === null) {
    return null;
  }
  const { markup_type: taskType, settings } = taskData;

  const [Batch, Buttons] = TaskFactory.createTask(taskType);
  const childProps = {
    ...props,
    batchImages: images,
    disabled: true,
    handleHistoryBackButtonClick: () => {},
  };

  // set visibility for loaded batch
  let cls = styles["batch-container"];
  if (images !== null) {
    cls = styles["loaded-batch-container"];
  }

  return (
    <div>
      <span>{taskData.user}</span>
      <div className="text-right">
        <DeleteMarkupButton />
      </div>
      <div className="text-center">
        <div className={cls}>
          {images !== null ? (
            <div>
              <Batch {...childProps} />
            </div>
          ) : null}
        </div>
        <Buttons {...childProps} settings={settings} />
      </div>
    </div>
  );
};

export default Viewer;
