import React, { useState } from "react";
import { Button, Col, Row, Spinner, Alert } from "react-bootstrap";
import { ClassInput, placeholders } from "./TaskClassesCreateList";
import useClassRequest from "./useClassRequest";

const TaskClassCreate = ({ task_id, classes }) => {
  const [newItemClass, setNewItemClass] = useState("");
  const { addClass, updateTasks, loading, errors, dropErrors } =
    useClassRequest();

  const classesNames = classes.reduce((acc, item) => {
    acc[item.markup_label] = true;
    return acc;
  }, {});

  const handleClassInput = (e) => {
    setNewItemClass(e.target.value);
  };

  const clearFields = () => {
    setNewItemClass("");
  };

  const createItem = async () => {
    const task = await addClass(task_id, newItemClass);
    if (!task) return;
    updateTasks(task);
    clearFields();
  };

  return (
    <div>
      <Alert
        className="mt-3 mb-1"
        show={errors.length}
        variant="danger"
        onClose={() => dropErrors()}
        dismissible
      >
        {errors.map((error, i) => (
          <p key={i}>{error}</p>
        ))}
      </Alert>
      <Row className="mt-1">
        <Col className="col-xs-5 col-sm-5 col-md-5">
          <ClassInput
            disabled={false}
            value={newItemClass}
            placeholder={placeholders.markupLabel}
            handleClassInput={handleClassInput}
          />
        </Col>
        <Col className="col-xs-2 col-sm-2 col-md-2">
          <Button
            block
            variant="success"
            onClick={createItem}
            disabled={!newItemClass || classesNames[newItemClass]}
          >
            +
          </Button>
        </Col>
        {loading && <Spinner animation="border" role="status" />}
      </Row>
    </div>
  );
};

export default TaskClassCreate;
