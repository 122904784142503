import React from "react";

const DefaultViewBlock = ({ item, names }) => {
  const totalTimeInHours = Math.floor(item.totalTime / 3600);
  const totalTimeMinutes = Math.floor(
    (item.totalTime / 3600 - totalTimeInHours) * 60
  );
  const avgSpeed = Math.floor(item.totalBatches / (item.totalTime / 3600));
  return (
    <div>
      <p>Для {`${names[item.id]}`}</p>
      <ul>
        <li>
          Затраченное время: {`${totalTimeInHours}ч ${totalTimeMinutes}м`}
        </li>
        <li>Всего батчей: {item.totalBatches}</li>
        <li>Скорость разметки: {avgSpeed} батчей / час</li>
        <li>
          Среднее время выполнения одного батча:{" "}
          {((1 / avgSpeed) * 3600).toFixed(1)} сек / батч
        </li>
        <li>Число превышений порога: {item.totalExceeded}</li>
      </ul>
    </div>
  );
};

export default DefaultViewBlock;
