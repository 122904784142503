import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { push } from "connected-react-router";
import markupApi from "../../markupApi";

const ErrorDict: { [key: string]: string } = {
  "batch.BatchNotFound": "NO_MORE_BATCHES",
  "image.ImageNotFound": "IMAGE_NOT_FOUND",
  "common.AccessDenied": "BATCH_DISSOCIATED",
};

const useTaskCore = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { taskId } = useParams<{ taskId: string }>();
  const { batchId, batchImages } = useSelector(
    (state) => (state as any).taskCoreReducer
  );

  const preRequestAction = () => {
    setError("");
    setLoading(true);
  };

  const initTask = async () => {
    preRequestAction();
    try {
      const task = await markupApi.getTask(taskId);
      const batch = await markupApi.getBatchToMarkup(taskId);
      dispatch({
        type: "INIT_TASK",
        markupType: (task as any).markup_type,
        settings: (task as any).settings,
      });
      dispatch(push(`/tasks/${taskId}?batch_id=${batch.id}`));
    } catch (e: any) {
      const { error_code, message } = e.response.data;
      setError(ErrorDict[error_code] || message || "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const nextBatch = async (state: "done" | "skipped") => {
    preRequestAction();
    try {
      // save current batch & it's images
      for (const img of batchImages) {
        if (img.markup !== null) {
          await markupApi.updateImage(img.id, img.markup);
        }
      }
      await markupApi.updateBatch(batchId, state);
      // upload new batch
      const batch = await markupApi.getBatchToMarkup(taskId);
      // triggers update batch
      dispatch(push(`/tasks/${taskId}?batch_id=${batch.id}`));
    } catch (e: any) {
      const { error_code, message } = e.response.data;
      setError(ErrorDict[error_code] || message || "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const updateBatch = async (batchId: number) => {
    preRequestAction();
    try {
      const batchImagesData = await markupApi.getImages(batchId as any);
      dispatch({
        type: "SET_MARKUP_CONTEXT",
        batchId,
        images: batchImagesData,
      });
    } catch (e: any) {
      const { error_code, message } = e.response.data;
      setError(ErrorDict[error_code] || message || "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  const saveBatch = () => nextBatch("done");
  const skipBatch = () => nextBatch("skipped");

  return { initTask, saveBatch, skipBatch, loading, error, updateBatch };
};

export default useTaskCore;
