import React, { useState, useEffect, useRef } from "react";
import InfiniteCalendar, { Calendar, withRange } from "react-infinite-calendar";
import { Button } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import styles from "./style.module.css";
import { disableDates } from "./utils";
import { useSelector } from "react-redux";
import { timetrackingSelector } from "../../../selectors";

const CalendarWithRange = withRange(Calendar);

const theme = {
  accentColor: "#484E53",
  floatingNav: {
    background: "rgba(56, 87, 138, 0.94)",
    chevron: "#FFA726",
    color: "#FFF",
  },
  headerColor: "#343a40",
  selectionColor: "#343a40",
  textColor: {
    active: "#FFF",
    default: "#333",
  },
  todayColor: "#dc3545",
  weekdayColor: "#484E53",
};

function useHide(ref, foo) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        foo();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}

const DatePicker = ({ handleDateChange, availableDates, enableAllDates }) => {
  const dateRef = useRef(null);
  const { minDate, maxDate, disabledDates } = disableDates(
    availableDates,
    enableAllDates
  );
  const { from, to } = useSelector(timetrackingSelector);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const hide = () => {
    setShowDatePicker(false);
  };
  useHide(dateRef, hide);
  return (
    <div ref={dateRef}>
      <Button
        variant="outlined"
        onClick={() => setShowDatePicker(!showDatePicker)}
        sx={{ mb: 1 }}
        endIcon={<DateRangeIcon />}
        fullWidth
      >
        {from && to ? `${from} — ${to}` : "Выберите Даты"}
      </Button>
      {showDatePicker && (
        <>
          <Button
            onClick={() => {
              handleDateChange({ from: "2021-07-01", to: new Date() });
            }}
          >
            Сбросить Дату
          </Button>
          <InfiniteCalendar
            Component={CalendarWithRange}
            locale={{
              headerFormat: "MMM Do",
            }}
            onSelect={(e) => {
              if (e.eventType === 3) {
                handleDateChange({ from: e.start, to: e.end });
              }
            }}
            min={minDate}
            max={maxDate}
            minDate={minDate}
            maxDate={maxDate}
            disabledDates={disabledDates}
            theme={theme}
            className={styles["date-picker"]}
          />
        </>
      )}
    </div>
  );
};

export default DatePicker;
