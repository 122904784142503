import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import styles from "./multi-binary-task.module.css";

const HotkeyEnum = {
  Q: 81,
  W: 87,
  E: 69,

  A: 65,
  S: 83,
  D: 68,

  Z: 90,
  X: 88,
  C: 67,

  skip: 32,
  save: 13,
  goBack: 8,
};

export default function MultiBinaryTaskButtons({
  batchImages,
  disabled,
  handleHistoryBackButtonClick,
  handleSave,
  batchId,
  handleSkip,
}) {
  const [images, setImages] = useState(null);

  const dispatch = useDispatch();

  const goBackButton = useRef();
  const saveButton = useRef();
  const skipButton = useRef();

  useEffect(() => {
    if (!batchId) {
      setImages(null);
      return;
    }
    if (batchImages) {
      const initImages = batchImages.slice();
      initImages.forEach((img) => {
        if (img.markup === null) {
          img.markup = {
            marked: false,
          };
        }
      });
      dispatch({
        type: "INIT_MARKUP",
        initImages: initImages,
      });
      setImages(batchImages);
    }
  }, [batchId]);

  useEffect(() => {
    const handleMarkup = (id) => {
      if (disabled) {
        return;
      }
      if (id >= images.length) {
        return;
      }
      const imageId = images[id].id;
      const prevMarkup = images[id].markup;
      const newMarkup = !prevMarkup.marked;

      const markup = {
        marked: newMarkup,
      };
      dispatch({
        type: "SET_MARKUP",
        imageId: imageId,
        markup: markup,
      });
    };

    const handleHotkeys = (e) => {
      switch (e.keyCode) {
        case HotkeyEnum.Q:
          handleMarkup(0);
          break;
        case HotkeyEnum.W:
          handleMarkup(1);
          break;
        case HotkeyEnum.E:
          handleMarkup(2);
          break;

        case HotkeyEnum.A:
          handleMarkup(3);
          break;
        case HotkeyEnum.S:
          handleMarkup(4);
          break;
        case HotkeyEnum.D:
          handleMarkup(5);
          break;

        case HotkeyEnum.Z:
          handleMarkup(6);
          break;
        case HotkeyEnum.X:
          handleMarkup(7);
          break;
        case HotkeyEnum.C:
          handleMarkup(8);
          break;

        case HotkeyEnum.skip:
          skipButton.current.click();
          break;
        case HotkeyEnum.goBack:
          goBackButton.current.click();
          break;
        case HotkeyEnum.save:
          saveButton.current.click();
          break;

        default:
          break;
      }
    };

    window.addEventListener("keydown", handleHotkeys);
    return () => {
      window.removeEventListener("keydown", handleHotkeys);
    };
  }, [images, batchImages, dispatch, disabled]);

  return (
    <Row className="mb-0 mt-3">
      <Col>
        <div className={styles["button-container"]}>
          <div className={styles["button-hint"]}>Backspace</div>
          <Button
            disabled={disabled}
            ref={goBackButton}
            variant="outline-info"
            onClick={handleHistoryBackButtonClick}
          >
            Назад
          </Button>
        </div>
      </Col>
      <Col>
        <div className={styles["button-container"]}>
          <div className={styles["button-hint"]}>Space</div>
          <Button
            disabled={disabled}
            ref={skipButton}
            variant="outline-info"
            onClick={handleSkip}
          >
            Пропустить
          </Button>
        </div>
      </Col>
      <Col>
        <div className={styles["button-container"]}>
          <div className={styles["button-hint"]}>Enter</div>
          <Button
            disabled={disabled}
            ref={saveButton}
            variant="outline-info"
            onClick={handleSave}
          >
            Готово
          </Button>
        </div>
      </Col>
    </Row>
  );
}
