import React from "react";
import { Button } from "@mui/material";

const DropQueryButton = ({ handleClick }) => {
  return (
    <Button
      color="error"
      variant="contained"
      sx={{ maxHeight: 56 }}
      onClick={handleClick}
    >
      Сбросить фильтры
    </Button>
  );
};

export default DropQueryButton;
