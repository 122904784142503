import React from "react";
import { useDispatch } from "react-redux";
import { Container, Image, Row } from "react-bootstrap";

import styles from "./sequence-task.module.css";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const { API_URL } = window;
export default function SequenceTaskBatch({ batchImages }) {
  const dispatch = useDispatch();

  const images = batchImages.slice();

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    image.styleCls =
      image.markup !== null && image.markup.marked
        ? styles["selected-image"]
        : styles.image;
  }

  const handleMarkup = (imageId) => {
    const image = images.filter((i) => i.id === imageId)[0];
    const prevMarkup = image.markup;
    const newMarkup = !prevMarkup?.marked;

    const markup = {
      marked: newMarkup,
    };
    dispatch({
      type: "SET_MARKUP",
      imageId: imageId,
      markup: markup,
    });
  };

  return (
    <Container className="task-container text-center">
      <Container className={styles["batch-container"]}>
        <Row className="justify-content-center">
          {images.map((img) => {
            const containerClasses = [
              "col-2",
              "col-sm-2",
              "col-md-2",
              "p-2",
              styles["batch-image-container"],
              img.markup !== null && img.markup.marked
                ? styles["selected-image"]
                : null,
            ];
            const imgClasses = [styles["batch-image"], img.styleCls];
            return (
              <div
                key={img.id}
                className={containerClasses.join(" ")}
                onClick={() => handleMarkup(img.id)}
              >
                <TransformWrapper panning={{ disabled: true }}>
                  <TransformComponent>
                    <Image
                      className={imgClasses.join(" ")}
                      fluid
                      src={`${API_URL}/images/${img.id}/image`}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}
