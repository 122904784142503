import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMarkup,
  loadPage,
  selectNextBatch,
  selectPrevBatch,
} from "../../../../../../actions/adminPanel";
import HotkeyManager from "../../../../../Utils/HotkeyManager";
import PaginatedList from "../../../../../Utils/PaginatedList/component";
import { adminPanelSelector } from "../../../../selectors";
import { markupQuerySelector } from "../selectors";
import BatchListItem from "./BatchListItem";

import "./style.css";
import { batchesSelector } from "./selectors";

const BatchList = () => {
  const { taskId, batchId, page, totalPages } =
    useSelector(markupQuerySelector);
  const { isFetching } = useSelector(adminPanelSelector);
  const batches = useSelector(batchesSelector);
  const dispatch = useDispatch();
  const tab = useSelector((state) => {
    const query = state.router.location.query;
    return "tab" in query ? query.tab : null;
  });

  useEffect(() => {
    if (page === null) {
      dispatch(loadPage(0));
    }
  }, [page, dispatch]);

  const renderCallback = (b) => (
    <BatchListItem selectedBatchId={batchId} taskId={taskId} batch={b} />
  );

  const bindings = {
    37: () => dispatch(loadPage(page > 0 ? page - 1 : page)),
    38: () => dispatch(selectPrevBatch()),
    39: () => dispatch(loadPage(page + 1)),
    40: () => dispatch(selectNextBatch()),
    46: () => dispatch(deleteMarkup()),
  };

  return batches !== null ? (
    <HotkeyManager
      bindings={bindings}
      disabled={tab !== "markup" || isFetching}
    >
      <div className="batches-list">
        <PaginatedList
          items={batches}
          page={page}
          totalPages={totalPages}
          renderCallback={renderCallback}
          handlePageChange={(p) => dispatch(loadPage(p))}
        />
      </div>
    </HotkeyManager>
  ) : null;
};

export default BatchList;
