import { handleActions } from "redux-actions";

const defaultState = {
  availableUsers: [],
  availableDates: [],
  availableTasks: [],
  selectedUsers: [],
  selectedTasks: [],
  from: null,
  to: null,
  timeThreshold: null,
  minThreshold: 1,
  maxThreshold: 300,
  timeReportData: {},
  nameDictionary: {},
};

export default handleActions(
  {
    CHANGE_TIMETRACKING_USERS: (state, action) => {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    },
    CHANGE_TIMETRACKING_TASKS: (state, action) => {
      return {
        ...state,
        selectedTasks: action.payload,
      };
    },
    CHANGE_TIMETRACKING_THRESHOLD: (state, action) => {
      const threshold = action.payload;
      const { minThreshold, maxThreshold } = state;
      if (threshold < minThreshold || threshold > maxThreshold) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        timeThreshold: threshold,
      };
    },
    DROP_TIMETRACKING: (state, action) => {
      return {
        ...state,
        selectedUsers: [],
        selectedTasks: [],
        from: null,
        to: null,
        timeThreshold: null,
        minThreshold: 1,
        maxThreshold: 300,
        timeReportData: {},
      };
    },
    CHANGE_TIMETRACKING_DATE: (state, action) => {
      const { from, to } = action.payload;
      return {
        ...state,
        from: from,
        to: to,
      };
    },
    SET_TIME_REPORT_DATA: (state, action) => {
      return {
        ...state,
        timeReportData: action.payload,
      };
    },
    SET_AVAILABLE_ITEMS: (state, action) => {
      const { availableDates, availableTasks, availableUsers } = action.payload;
      return {
        ...state,
        availableDates: availableDates,
        availableUsers: availableUsers,
        availableTasks: availableTasks,
      };
    },
    SET_NAME_DICT: (state, action) => {
      return {
        ...state,
        nameDictionary: action.payload,
      };
    },
  },
  defaultState
);
