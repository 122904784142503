import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import history from "../history";
import adminPanelReducer from "./adminPanel";
import taskCoreReducer from "./taskCore";
import userReducer from "./user";
import timetrackingReducer from "./timetracking";

const appReducer = combineReducers({
  adminPanelReducer,
  taskCoreReducer,
  userReducer,
  timetrackingReducer,
  router: connectRouter(history),
});

export default (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
