import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UserManager from "./UserManager";
import TaskMarkupDownloader from "./TaskMarkupDownloader";
import TaskClass from "./TaskClass";
import styles from "./task-panel.module.css";
import TaskClassCreate from "./TaskClassCreate";
import { adminPanelSelector } from "../../selectors";
import useTaskRequests from "./useTaskRequests";

const initialState = {
  name: "",
};

export default function TaskForm({ task }) {
  const [formData, setFormData] = useState(Object.assign({}, initialState));
  const { tasks } = useSelector(adminPanelSelector);
  const { deleteTaskRequest, updateTaskRequest, errors } = useTaskRequests();
  const [showModal, setShowModal] = useState(false);

  const isFetching = useSelector((state) => state.adminPanelReducer.isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(Object.assign({}, initialState));
  }, [task]);

  let fieldsEmpty = true;
  for (const [key] of Object.entries(formData)) {
    if (formData[key] !== "") {
      fieldsEmpty = false;
      break;
    }
  }
  const canSave = !fieldsEmpty;

  const saveData = async () => {
    const data = {};
    for (const [key, value] of Object.entries(formData)) {
      if (formData[key] !== "") {
        data[key] = value;
      }
    }
    const updatedTask = await updateTaskRequest(task.id, data);
    if (!updatedTask) return;
    const updatedTasksList = tasks.map((task) => {
      if (task.id === updatedTask.id) {
        return updatedTask;
      }
      return task;
    });
    dispatch({
      type: "UPDATE_TASKS",
      payload: { tasks: updatedTasksList, task: updatedTask },
    });
  };

  const removeTask = async () => {
    const success = await deleteTaskRequest(task.id);
    setShowModal(false);
    if (!success) return;
    const updatedTasksList = tasks.filter(
      (taskFromList) => task.id !== taskFromList.id
    );
    dispatch({
      type: "UPDATE_TASKS",
      payload: {
        tasks: updatedTasksList,
        task: updatedTasksList[updatedTasksList.length - 1] || null,
      },
    });
  };

  const updateFormField = (key, e) => {
    const newFormData = { ...formData };
    newFormData[key] = e.target.value;
    setFormData(newFormData);
  };

  return (
    <Col className="mt-2 col-8 offset-2">
      <Alert show={errors.length} variant="danger">
        {errors.map((error, i) => (
          <p key={i}>{error}</p>
        ))}
      </Alert>
      <Form>
        <Form.Group key="Название">
          <Form.Label>Название</Form.Label>
          <Form.Control
            value={formData.name}
            onChange={(e) => updateFormField("name", e)}
            placeholder={task.name}
          />
        </Form.Group>
        <Form.Group key="Номер задания">
          <Form.Label>Номер задания</Form.Label>
          <Form.Control disabled placeholder={task.id} />
        </Form.Group>
        <Form.Group key="Тип разметчика">
          <Form.Label>Тип разметчика</Form.Label>
          <Form.Control disabled placeholder={task.markupLabel} />
        </Form.Group>
        <Form.Group key="Количество изображений в батче">
          <Form.Label>Количество изображений в батче</Form.Label>
          <Form.Control
            disabled
            placeholder={
              task.batchSize === null ? "Варьируется" : task.batchSize
            }
          />
        </Form.Group>
        {task.markup_type === "classification" && (
          <Form.Group key="Классы">
            <Form.Label>Классы</Form.Label>
            {task.settings.classes.map((cls, i) => {
              return (
                <TaskClass
                  cls={cls}
                  classes={task.settings.classes}
                  task_id={task.id}
                  key={cls.markup_label + i}
                />
              );
            })}
            <TaskClassCreate
              task_id={task.id}
              classes={task.settings.classes}
            />
          </Form.Group>
        )}

        <Container className="mt-1 mb-1">
          <Row>
            <Button
              className="mr-auto"
              variant="success"
              disabled={!canSave || isFetching}
              onClick={saveData}
            >
              Сохранить
            </Button>
            <Button
              variant="danger"
              disabled={isFetching}
              onClick={() => setShowModal(true)}
            >
              Удалить
            </Button>
          </Row>
        </Container>
      </Form>
      <div className="mt-3">
        <TaskMarkupDownloader taskId={task.id} />
      </div>
      <Container className={styles["user-manager"]}>
        <UserManager task={task} />
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление задания</Modal.Title>
        </Modal.Header>
        <Modal.Body>Все пользователи будут откреплены. Вы уверены?</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isFetching}
            type="submit"
            variant="danger"
            onClick={removeTask}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
