import { fromUnixTime } from "../../../../../../utils";

export const getPlotData = (data, dataKey, totalDataKey) => {
  const x = [];
  const y = [];

  for (const dayInfo of data.days_info) {
    const day = new Date(fromUnixTime(dayInfo.date))
      .toISOString()
      .split("T")[0];

    x.push(day);
    y.push(dayInfo[dataKey]);
  }

  return {
    x,
    y,
    total: data[totalDataKey],
  };
};

export const getAverageSpeed = (batches, seconds) => {
  return Math.floor(batches / (seconds / 3600));
};

const addZeroToDigit = (digit) => {
  if (digit < 10) {
    return "0" + digit;
  }
  return digit;
};

export const convertSeconds = (totalTimeInSeconds) => {
  let hours = Math.floor(totalTimeInSeconds / 3600);
  let minutes = Math.floor((totalTimeInSeconds % 3600) / 60);

  return `${hours}ч ${minutes}м`;
};

export const convertToArray = (obj) => {
  const array = [];
  for (const [id, item] of Object.entries(obj)) {
    array.push({ id, ...item });
  }
  return array;
};

const generateColor = () => {
  const red = Math.round(Math.random() * 255);
  const green = Math.round(Math.random() * 255);
  const blue = Math.round(Math.random() * 255);
  return `rgb(${red}, ${green}, ${blue})`;
};

export const setColorForItem = (itemColors, itemId) => {
  if (!itemColors[itemId]) {
    itemColors[itemId] = generateColor();
  }
  return itemColors[itemId];
};

export const convertTime = (timeString) => {
  const regexpTime = /((?<hours>\d+)h)?((?<minutes>\d+)m)?(?<seconds>(\d+)s)?/;
  const match = regexpTime.exec(timeString);
  let { hours, minutes, seconds } = match.groups;
  hours = hours ? parseInt(hours) : 0;
  minutes = minutes ? parseInt(minutes) : 0;
  seconds = seconds ? parseInt(seconds) : 0;
  return hours * 3600 + minutes * 60 + seconds;
};

// adds totalTime/totalBatches/totalExceededThersholds to object
export const addTotalItem = (fieldName, dict, id, value, obj) => {
  const assigningValue = fieldName === "totalTime" ? convertTime(value) : value;
  if (dict[id]) {
    if (dict[id][fieldName]) {
      dict[id][fieldName] += assigningValue;
    } else {
      dict[id][fieldName] = assigningValue;
    }
  } else {
    dict[id] = { ...obj };
    dict[id][fieldName] = assigningValue;
  }
};

export const parseTimeReport = (dates) => {
  const tasks = {};
  const users = {};
  for (const [date, dateObject] of Object.entries(dates)) {
    for (const [taskId, taskObject] of Object.entries(dateObject.tasks)) {
      for (const [userId, userObject] of Object.entries(taskObject.users)) {
        addTotalItem(
          "totalTime",
          tasks,
          taskId,
          userObject.time_spent,
          taskObject
        );
        addTotalItem(
          "totalTime",
          users,
          userId,
          userObject.time_spent,
          userObject
        );
        addTotalItem(
          "totalBatches",
          tasks,
          taskId,
          userObject.batches_marked,
          taskObject
        );
        addTotalItem(
          "totalBatches",
          users,
          userId,
          userObject.batches_marked,
          userObject
        );
        addTotalItem(
          "totalExceeded",
          tasks,
          taskId,
          userObject.threshold_exceed_count,
          taskObject
        );
        addTotalItem(
          "totalExceeded",
          users,
          userId,
          userObject.threshold_exceed_count,
          userObject
        );
      }
    }
  }
  return { usersObj: users, tasksObj: tasks };
};

export const fillHistograms = (dates, names, itemColors) => {
  const byTimeTasks = [];
  const byBatchesTasks = [];
  const avgSpeedTasks = [];
  const byThresholdTasks = [];

  const byTimeUsers = [];
  const byBatchesUsers = [];
  const avgSpeedUsers = [];
  const byThresholdUsers = [];

  const byTimeTotal = [];
  const byBatchesTotal = [];
  const byThresholdTotal = [];

  const legends = [];
  const avgSpeedLegends = [];

  // хранилище для суммарных значений батчей, потраченного времени, превышений порога
  const totalValues = {
    tasks: {},
    users: {},
  };

  for (const [dateName, dateObj] of Object.entries(dates)) {
    let totalTime = 0;
    let totalBatches = 0;
    let totalThreshold = 0;
    for (const [taskId, taskObj] of Object.entries(dateObj.tasks)) {
      for (const [userId, userObj] of Object.entries(taskObj.users)) {
        totalTime += convertTime(userObj.time_spent);
        totalBatches += userObj.batches_marked;
        totalThreshold += userObj.threshold_exceed_count;
        addTotalItem(
          "totalTime",
          totalValues.users,
          userId,
          userObj.time_spent,
          {}
        );
        addTotalItem(
          "totalBatches",
          totalValues.users,
          userId,
          userObj.batches_marked,
          {}
        );
        addTotalItem(
          "totalTime",
          totalValues.tasks,
          taskId,
          userObj.time_spent,
          {}
        );
        addTotalItem(
          "totalBatches",
          totalValues.tasks,
          taskId,
          userObj.batches_marked,
          {}
        );

        // данные для отображения
        const usersCommon = {
          y: [],
          x: [[], []],
          type: "bar",
          textposition: "auto",
          text: names.tasks[taskId],
          name: names.tasks[taskId],
          hoverinfo: "x+y+z+text",
          marker: {
            color: setColorForItem(itemColors, `T${taskId}`),
          },
          showlegend: !legends.includes(`T${taskId}`),
        };
        const tasksCommon = {
          y: [],
          x: [[], []],
          type: "bar",
          text: names.users[userId],
          name: names.users[userId],
          textposition: "auto",
          hoverinfo: "x+y+z+text",
          marker: {
            color: setColorForItem(itemColors, `U${userId}`),
          },
          showlegend: !legends.includes(`U${userId}`),
        };
        const timeDataUsers = JSON.parse(JSON.stringify(usersCommon));
        const batchesDataUsers = JSON.parse(JSON.stringify(usersCommon));
        const dataThresholdUsers = JSON.parse(JSON.stringify(usersCommon));
        const timeDataTasks = JSON.parse(JSON.stringify(tasksCommon));
        const batchesDataTasks = JSON.parse(JSON.stringify(tasksCommon));
        const dataThresholdTasks = JSON.parse(JSON.stringify(tasksCommon));

        const taskName = names.tasks[taskId];
        const userName = names.users[userId];
        const totalSeconds = convertTime(userObj.time_spent); // перевести время в секунды
        const convertedTime = new Date(totalSeconds * 1000).toISOString(); // получить unix дату
        // заполнение оси У по задачам
        timeDataTasks.y.push(convertedTime);
        batchesDataTasks.y.push(userObj.batches_marked);
        dataThresholdTasks.y.push(userObj.threshold_exceed_count);
        // заполнение оси У по юзерам
        timeDataUsers.y.push(convertedTime);
        batchesDataUsers.y.push(userObj.batches_marked);
        dataThresholdUsers.y.push(userObj.threshold_exceed_count);
        // заполнение оси дат по задачам и юзерам
        [
          timeDataTasks,
          batchesDataTasks,
          dataThresholdTasks,
          timeDataUsers,
          batchesDataUsers,
          dataThresholdUsers,
        ].forEach((data) => {
          data.x[0].push(dateName);
        });
        // заполнение оси X задач
        [timeDataTasks, batchesDataTasks, dataThresholdTasks].forEach(
          (data) => {
            data.x[1].push(taskName);
          }
        );
        // заполнение оси X юзеров
        [timeDataUsers, batchesDataUsers, dataThresholdUsers].forEach(
          (data) => {
            data.x[1].push(userName);
          }
        );

        byTimeTasks.push(timeDataTasks);
        byBatchesTasks.push(batchesDataTasks);
        byThresholdTasks.push(dataThresholdTasks);
        byTimeUsers.push(timeDataUsers);
        byBatchesUsers.push(batchesDataUsers);
        byThresholdUsers.push(dataThresholdUsers);
        legends.push(`U${userId}`, `T${taskId}`);
      }
    }
    fillAvgSpeeds(
      totalValues,
      names,
      dateName,
      itemColors,
      avgSpeedLegends,
      avgSpeedTasks,
      avgSpeedUsers
    );
    fillTotal(
      byTimeTotal,
      byBatchesTotal,
      byThresholdTotal,
      totalTime,
      dateName,
      totalBatches,
      totalThreshold
    );
  }
  return {
    byTimeTasks,
    byBatchesTasks,
    byThresholdTasks,
    avgSpeedTasks,
    byTimeUsers,
    byBatchesUsers,
    byThresholdUsers,
    avgSpeedUsers,
    byTimeTotal,
    byBatchesTotal,
    byThresholdTotal,
    totalValues,
  };
};

const fillAvgSpeeds = (
  totalValues,
  names,
  dateName,
  itemColors,
  avgSpeedLegends,
  avgSpeedTasks,
  avgSpeedUsers
) => {
  for (const [taskId, taskObj] of Object.entries(totalValues.tasks)) {
    const taskName = names.tasks[taskId];

    const currentDataAvgSpeed = {
      y: [(taskObj.totalTime / taskObj.totalBatches).toFixed(1)],
      x: [[dateName], [taskName]],
      type: "bar",
      text: names.tasks[taskId],
      name: names.tasks[taskId],
      textposition: "auto",
      xaxis: {
        automargin: false,
        tickangle: 180,
      },
      marker: {
        color: setColorForItem(itemColors, `T${taskId}`),
      },
      showlegend: !avgSpeedLegends.includes(`T${taskId}`),
    };
    avgSpeedLegends.push(`T${taskId}`); // добавить id в уже указанные в легенде графика
    avgSpeedTasks.push(currentDataAvgSpeed);
  }
  for (const [userId, userObj] of Object.entries(totalValues.users)) {
    const userName = names.users[userId];
    const currentDataAvgSpeed = {
      y: [(userObj.totalTime / userObj.totalBatches).toFixed(1)],
      x: [[dateName], [userName]],
      type: "bar",
      text: names.users[userId],
      name: names.users[userId],
      textposition: "auto",
      marker: {
        color: setColorForItem(itemColors, `U${userId}`),
      },
      showlegend: !avgSpeedLegends.includes(`U${userId}`),
    };
    avgSpeedLegends.push(`U${userId}`); // добавить id в уже указанные в легенде графика
    avgSpeedUsers.push(currentDataAvgSpeed);
  }
};

const fillTotal = (
  byTimeTotal,
  byBatchesTotal,
  byThresholdTotal,
  totalTime,
  dateName,
  totalBatches,
  totalThreshold
) => {
  byTimeTotal.push({
    y: [new Date(totalTime * 1000).toISOString()],
    x: [[dateName], [""]],
    type: "bar",
    textposition: "outside",
    hoverinfo: ["skip"],
    text: convertSeconds(totalTime),
    name: convertSeconds(totalTime),
    marker: {
      color: "#444",
    },
    showlegend: false,
  });
  byBatchesTotal.push({
    y: [totalBatches],
    x: [[dateName], [""]],
    type: "bar",
    text: totalBatches,
    name: totalBatches,
    textposition: "outside",
    hoverinfo: ["skip"],
    xaxis: {
      automargin: false,
      tickangle: 180,
    },
    marker: {
      color: "#444",
    },
    showlegend: false,
  });
  byThresholdTotal.push({
    y: [totalThreshold],
    x: [[dateName], [""]],
    type: "bar",
    text: totalThreshold,
    name: totalThreshold,
    textposition: "outside",
    hoverinfo: ["skip"],
    xaxis: {
      automargin: false,
      tickangle: 180,
    },
    marker: {
      color: "#444",
    },
    showlegend: false,
  });
};

const foo = (names, taskId, legends, itemColors) => {
  const timeDataUsers = {
    text: names.tasks[taskId],
    name: names.tasks[taskId],
    marker: {
      color: setColorForItem(itemColors, `T${taskId}`),
    },
    showlegend: !legends.includes(`T${taskId}`),
  };
  const batchesDataUsers = {
    text: names.tasks[taskId],
    name: names.tasks[taskId],
    marker: {
      color: setColorForItem(itemColors, `T${taskId}`),
    },
    showlegend: !legends.includes(`T${taskId}`),
  };
  const dataThresholdUsers = {};
};
