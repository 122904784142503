import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignUser, dissUser } from "../../../../actions/adminPanel";
import ItemsManager from "../../../Utils/ItemsManager/component";
import { adminPanelSelector } from "../../selectors";
import { splitAssignedTasks } from "../utils";

const TaskManager = ({ user }) => {
  const { tasks } = useSelector(adminPanelSelector);
  const dispatch = useDispatch();

  if (tasks === null) {
    return null;
  }

  const { assignedItems: assignedTasks, dissociatedItems: dissociatedTasks } =
    splitAssignedTasks(tasks, user);

  const assignedProps = {
    topic: "Добавленные задания",
    items: assignedTasks,
    handleDoubleClick: (t) => dispatch(dissUser(user, t.id)),
  };
  const dissociatedProps = {
    topic: "Недобавленные задания",
    items: dissociatedTasks,
    handleDoubleClick: (t) => dispatch(assignUser(user, t.id)),
  };

  return (
    <ItemsManager
      itemKey="name"
      assignedProps={assignedProps}
      dissociatedProps={dissociatedProps}
    />
  );
};

export default TaskManager;
