export const getTasks = (dates) => {
  const task_ids = [];
  const tasks = [];
  for (const [date, dateObj] of Object.entries(dates)) {
    for (const [taskId, taskObj] of Object.entries(dateObj.tasks)) {
      if (!task_ids.includes(taskId)) {
        task_ids.push(taskId);
        tasks.push({ id: taskId, ...taskObj.users });
      }
    }
  }
  return tasks;
};

export const getUsers = (dates) => {
  let user_ids = [];
  const users_objects = [];
  for (const [date, dateObj] of Object.entries(dates)) {
    for (const [taskId, taskObj] of Object.entries(dateObj.tasks)) {
      // for participation
      if (Array.isArray(taskObj.users)) {
        user_ids = [...user_ids, ...taskObj.users];
      }
      // for time_report
      else {
        return taskObj.users;
      }
    }
  }
  return [...new Set(user_ids)];
};

export const getDates = (dates) => {
  return Object.keys(dates);
};

export const formatDate = (date) => {
  const fromDate = new Date(date.from);
  const toDate = new Date(date.to);
  let fromDay = fromDate.getDate() + "";
  let toDay = toDate.getDate() + "";
  let fromMonth = fromDate.getMonth() + 1 + "";
  let toMonth = toDate.getMonth() + 1 + "";
  fromDay = fromDay.length === 1 ? "0" + fromDay : fromDay;
  toDay = toDay.length === 1 ? "0" + toDay : toDay;
  fromMonth = fromMonth.length === 1 ? "0" + fromMonth : fromMonth;
  toMonth = toMonth.length === 1 ? "0" + toMonth : toMonth;

  return {
    from: `${fromDate.getFullYear()}-${fromMonth}-${fromDay}`,
    to: `${toDate.getFullYear()}-${toMonth}-${toDay}`,
  };
};

const convertStrToDate = (str) => {
  return new Date(str).setHours(0, 0, 0, 0);
};

const getRangeDates = (minDate, maxDate) => {
  const dateList = [];
  const firstDate = new Date(minDate);
  const secondDate = new Date(maxDate);
  while (firstDate < secondDate) {
    firstDate.setDate(firstDate.getDate() + 1);
    const date = new Date(firstDate).setHours(0, 0, 0, 0);
    dateList.push(new Date(date));
  }
  return dateList;
};

const getDisabledDates = (dateList, enabledDates) => {
  return dateList.filter((date) => !enabledDates.includes(date.getTime()));
};

export const disableDates = (dates, enableAllDates) => {
  if (enableAllDates) {
    return {
      minDate: new Date("2019-01-01"),
      maxDate: new Date(),
      disabledDates: [],
    };
  }
  let minDate = new Date();
  let maxDate = new Date();
  let disabledDates = [new Date()];
  // если объект dates в стейте не пустой
  if (dates.length) {
    const enabledDates = dates.map((date) => convertStrToDate(date));
    enabledDates.sort((date1, date2) => date1 - date2);
    minDate = enabledDates[0];
    maxDate = enabledDates[dates.length - 1];
    const dateList = getRangeDates(minDate, maxDate);
    disabledDates = getDisabledDates(dateList, enabledDates);
    if (!enabledDates.length) {
      minDate = new Date();
      maxDate = new Date();
      disabledDates = [new Date()];
    }
  }
  return { minDate, maxDate, disabledDates };
};
