import OneImageBatch from "../Utils/OneImageBatch/OneImageBatch";

import BinaryTaskButtons from "./BinaryTask/BinaryTaskButtons";

import ClassificationTaskButtons from "./ClassificationTask/ClassificationTaskButtons";

import MultiBinaryTaskBatch from "./MultiBinaryTask/MultiBinaryTaskBatch";
import MultiBinaryTaskButtons from "./MultiBinaryTask/MultiBinaryTaskButtons";

import TextTaskButtons from "./TextTask/TextTaskButtons";

import SequenceTaskBatch from "./SequenceTask/SequenceTaskBatch";
import SequenceTaskButtons from "./SequenceTask/SequenceTaskButtons";

// NOTE: add new tasks here
const products = {
  text: [OneImageBatch, TextTaskButtons],
  binary: [OneImageBatch, BinaryTaskButtons],
  multi_binary: [MultiBinaryTaskBatch, MultiBinaryTaskButtons],
  classification: [OneImageBatch, ClassificationTaskButtons],
  sequence: [SequenceTaskBatch, SequenceTaskButtons],
};

// NOTE: add new task metadata here
export const TASK_MARKUP_TYPE = {
  text: {
    name: "Разметка текста",
    batchSize: 1,
    editableView: false,
  },
  binary: {
    name: "Бинарная классификация",
    batchSize: 1,
    editableView: false,
  },
  multi_binary: {
    name: "Бинарная классификация 3x3",
    batchSize: 9,
    editableView: false,
  },
  classification: {
    name: "Классификация",
    batchSize: 1,
    editableView: true,
  },
  sequence: {
    name: "Разметка последовательности",
    batchSize: null,
    editableView: false,
  },
};

const createTask = (type) => {
  // check invalid type passsing
  if (!(type in products)) {
    throw new Error(`${type} task doesn't exist`);
  }

  // create existing components
  return products[type];
};

export default {
  createTask,
  TASK_MARKUP_TYPE,
};
