import React from "react";
import { Container } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Img from "../../Utils/Img";

import styles from "./style.module.css";
const { API_URL } = window;
export default function OneImageBatch({ batchImages }) {
  return (
    <TransformWrapper panning={{ disabled: true }}>
      <Container className={styles["batch-container"]}>
        <TransformComponent>
          <Img src={`${API_URL}/images/${batchImages[0].id}/image`} />
        </TransformComponent>
      </Container>
    </TransformWrapper>
  );
}
