import { push } from "connected-react-router";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function AdminPanelNavbar() {
  const dispatch = useDispatch();

  return (
    <Navbar bg="dark" variant="dark">
      <Nav className="mr-auto" />
      <Nav>
        <Nav.Link className="mr-3" onClick={() => dispatch(push("/tasks"))}>
          Разметчик
        </Nav.Link>
        <Nav.Link onClick={() => dispatch(push("/logout"))}>Выйти</Nav.Link>
      </Nav>
    </Navbar>
  );
}
