import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

export default function NoTasksBanner() {
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <Alert variant="info">
        <Alert.Heading>Заданий нет</Alert.Heading>
        <hr />
        <div>
          В данный момент вы не прикреплены ни к одному заданию. Для детальной
          информации свяжитесь с администратором.
        </div>
      </Alert>
      <Button
        variant="outline-success"
        onClick={() => {
          dispatch(push("/logout"));
        }}
      >
        Выйти
      </Button>
    </div>
  );
}
