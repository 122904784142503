import { push } from "connected-react-router";
import { createAction } from "redux-actions";

export const registerGoBackEffect = (taskId) => {
  return (dispatch) => {
    window.onpopstate = () => {
      const params = new URL(document.location).searchParams;
      const idParam = params.get("batch_id");
      if (idParam === null) {
        dispatch(push("/tasks"));
      }
      dispatch({
        type: "GO_BACK",
        batchId: idParam,
      });
    };
  };
};

export const openTask = createAction("OPEN_TASK");

export const dropTask = createAction("DROP_TASK");
