import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { openTask } from "../../actions/taskCore";
import useTaskRequests from "../AdminPanel/AdminPanelMenu/TaskPanel/useTaskRequests";
import NoTasksBanner from "./NoTasksBanner";
import TaskList from "./TaskList";
import TaskMenuNavbar from "./TaskMenuNavbar";

export default function TaskMenu(props) {
  const [tasks, setTasks] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const { getTasksRequest } = useTaskRequests();

  const dispatch = useDispatch();

  useEffect(() => {
    const loadTasks = async () => {
      const tasksData = await getTasksRequest();
      setTasks(tasksData);
    };

    loadTasks();
  }, []);

  if (tasks !== null && tasks.length === 0) {
    return <NoTasksBanner />;
  }

  if (taskId !== null) {
    batch(() => {
      dispatch(openTask());
      dispatch(push(`/tasks/${taskId}`));
    });
    return null;
  }

  return (
    <div>
      <TaskMenuNavbar {...props} />
      <TaskList tasks={tasks} callbackHook={setTaskId} />
    </div>
  );
}
