import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { Alert, Container, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminPanelSelector } from "../selectors";
import { pathnameSelector } from "./selectors";
import TaskPanel from "./TaskPanel";
import TimetrackingPanel from "./TimetrackingPanel/component";
import UserPanel from "./UserPanel";
import MarkupPanel from "./MarkupPanel/component";
import useUserRequests from "./UserPanel/useUserRequests";
import useTaskRequests from "./TaskPanel/useTaskRequests";

export default function AdminPanelMenu(props) {
  const { currentUser } = props;
  const { users, tasks } = useSelector(adminPanelSelector);
  const { getUsersRequest, errors: usersErrors } = useUserRequests();
  const { getTasksRequest, errors: tasksErrors } = useTaskRequests();
  const pathname = useSelector(pathnameSelector);
  const dispatch = useDispatch();

  const userIsAdmin = currentUser.role === "admin";

  const fetchUsers = async () => {
    const users = await getUsersRequest();
    if (!users) return;
    dispatch({
      type: "UPDATE_USERS",
      payload: { users, user: users[0] || null },
    });
  };

  const fetchTasks = async () => {
    const tasks = await getTasksRequest();
    if (!tasks) return;
    dispatch({
      type: "UPDATE_TASKS",
      payload: { tasks, task: tasks[tasks.length - 1] || null },
    });
  };

  useEffect(() => {
    if (users === null) {
      fetchUsers();
    }
  }, [users]);

  useEffect(() => {
    if (tasks === null) {
      fetchTasks();
    }
  }, [tasks]);

  const tabToQueryString = (data) => {
    const url = pathname + `?tab=${data}`;
    dispatch(push(url));
  };

  const params = new URL(document.location).searchParams;
  let tab = params.get("tab");
  if (tab === null) {
    tab = userIsAdmin ? "tasks" : "markup";
  }

  return (
    <Tabs defaultActiveKey={tab} onSelect={tabToQueryString}>
      {userIsAdmin && (
        <Tab eventKey="users" title="Пользователи">
          <Container className="admin-container">
            <Alert show={usersErrors.length} variant="danger" className="mt-3">
              {usersErrors.map((error, i) => (
                <p key={i}>{error}</p>
              ))}
            </Alert>
            <UserPanel currentUser={currentUser} />
          </Container>
        </Tab>
      )}
      {userIsAdmin && (
        <Tab eventKey="tasks" title="Задания">
          <Container className="admin-container">
            <Alert show={tasksErrors.length} variant="danger" className="mt-3">
              {tasksErrors.map((error) => (
                <p>{error}</p>
              ))}
            </Alert>
            <TaskPanel currentUser={currentUser} />
          </Container>
        </Tab>
      )}
      <Tab eventKey="markup" title="Разметка">
        <Container className="admin-container">
          <MarkupPanel />
        </Container>
      </Tab>
      {userIsAdmin && (
        <Tab eventKey="timetracking" title="Таймтрекинг">
          <Container className="admin-container">
            <TimetrackingPanel />
          </Container>
        </Tab>
      )}
    </Tabs>
  );
}
