import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Divider = ({ divideBy, handleChange }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Разбить по:</FormLabel>
      <RadioGroup
        row
        aria-label="divide"
        name="row-radio-buttons-group"
        value={divideBy}
        onChange={(e) => handleChange(e.target.value)}
      >
        <FormControlLabel
          value="none"
          control={<Radio />}
          label="Без разбивки"
        />
        <FormControlLabel
          value="users"
          control={<Radio />}
          label="Пользователям"
        />
        <FormControlLabel value="tasks" control={<Radio />} label="Задачам" />
      </RadioGroup>
    </FormControl>
  );
};

export const DefaultViewDivider = ({ divideBy, handleChange }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Разбить по:</FormLabel>
      <RadioGroup
        row
        aria-label="divide"
        name="row-radio-buttons-group"
        value={divideBy}
        onChange={(e) => handleChange(e.target.value)}
      >
        <FormControlLabel
          value="users"
          control={<Radio />}
          label="Пользователям"
        />
        <FormControlLabel value="tasks" control={<Radio />} label="Задачам" />
      </RadioGroup>
    </FormControl>
  );
};

export default Divider;
