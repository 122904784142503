import { push } from "connected-react-router";

import { initCurrentUser } from "./user";
import MarkupApi from "../markupApi";

export const loadUserDataEffect = (userId, token) => {
  return async (dispatch, getState) => {
    const pathname = getState().router.location.pathname;
    const loginRedirectUrl = `/login?next=${pathname}`;

    if (userId === null || token === null || pathname === "/logout") {
      localStorage.removeItem("user_id");
      localStorage.removeItem("access_token");
      dispatch(push(loginRedirectUrl));
      return;
    }

    const currentUserData = await MarkupApi.getCurrentUser();
    if ("errorCode" in currentUserData) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("access_token");
      dispatch(push(loginRedirectUrl));
    } else {
      dispatch(initCurrentUser(currentUserData));
    }
  };
};
