import React from "react";
import { convertSeconds, getAverageSpeed } from "./utils";

const DefaultViewCommon = ({ tasks, users, nameDict }) => {
  // сумма времени выполнения всех задач каждым юзером
  const totalTimeSeconds = tasks.reduce(
    (timeSum, taskObject) => timeSum + taskObject.totalTime,
    0
  );
  const totalTime = convertSeconds(totalTimeSeconds);

  const totalBatches = tasks.reduce(
    (batchesSum, taskObject) => batchesSum + taskObject.totalBatches,
    0
  );

  const totalExceeded = tasks.reduce(
    (exceededSum, taskObject) => exceededSum + taskObject.totalExceeded,
    0
  );

  const avgSpeed = getAverageSpeed(totalBatches, totalTimeSeconds);
  return (
    <div>
      <p>
        Для пользователей:{" "}
        {users.map(
          (user, i) =>
            nameDict.users[user.id] + (users.length - 1 === i ? "" : ", ")
        )}
        <br />
        По задачам:{" "}
        {tasks.map(
          (task, i) =>
            nameDict.tasks[task.id] + (tasks.length - 1 === i ? "" : ", ")
        )}
      </p>
      <ul>
        <li>Затраченное время: {totalTime}</li>
        <li>Всего батчей: {totalBatches}</li>
        <li>Скорость разметки: {avgSpeed} батчей / час</li>
        <li>
          Среднее время выполнения одного батча:{" "}
          {((1 / avgSpeed) * 3600).toFixed(1)} сек / батч
        </li>
        <li>Число превышений порога: {totalExceeded}</li>
      </ul>
    </div>
  );
};

export default DefaultViewCommon;
