import React, { useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import FilterPanel from "./Filters/component";
import DefaultView from "./Viewer/Histograms/DefaultView";
import ByDayHistogram from "./Viewer/Histograms/ByDayHistogram";
import HistogramSwitcher, { useQuery } from "./Viewer/HistogramSwitcher";
import { adminPanelSelector } from "./../../selectors";
import { setNameDict } from "../../../../actions/timetracking";
import ThresholdsList from "./Viewer/Histograms/ThresholdsList";

const TimetrackingPanel = () => {
  const dispatch = useDispatch();
  const { tasks, users } = useSelector(adminPanelSelector); // used to get users and tasks names
  const query = useQuery();
  const name = query.get("view");

  // установить словарь с именами для tasks и users
  useEffect(() => {
    if (!tasks || !users) return;
    const dict = {
      tasks: {},
      users: {},
    };
    tasks.forEach((task) => (dict.tasks[task.id] = task.name));
    users.forEach((user) => (dict.users[user.id] = user.email));
    dispatch(setNameDict(dict));
  }, [users, tasks]);

  if (!tasks || !users) {
    return (
      <Tab.Container>
        <p>
          Не найдены
          {!tasks && " задачи "}
          {!tasks && !users && "и"}
          {!users && " пользователи "}
        </p>
      </Tab.Container>
    );
  }

  return (
    <Tab.Container>
      <FilterPanel />
      <ThresholdsList />
      <HistogramSwitcher />
      {!name && <DefaultView />}
      {name === "graph" && <ByDayHistogram />}
    </Tab.Container>
  );
};

export default TimetrackingPanel;
