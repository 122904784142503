import React from "react";
import { Tab, Col, Row } from "react-bootstrap";
import SideBar from "./SideBar/component";
import Viewer from "./Viewer/component";

const MarkupPanel = () => (
  <Tab.Container>
    <Row className="mt-3 ml-1">
      <Col className="col-2">
        <SideBar />
      </Col>
      <Col className="col-10">
        <Viewer />
      </Col>
    </Row>
  </Tab.Container>
);

export default MarkupPanel;
