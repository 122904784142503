import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import useUserRequests from "./useUserRequests";
import { useDispatch, useSelector } from "react-redux";
import { adminPanelSelector } from "../../selectors";

const initialState = {
  email: "",
  password: "",
  last_name: "",
  first_name: "",
  middle_name: "",
  role: "marker",
};

const placeholders = {
  email: "Введите email",
  password: "Введите пароль",
  last_name: "Введите фамилию",
  first_name: "Введите имя",
  middle_name: "Введите отчество",
};

function UserCreateModal(props) {
  const [formData, setFormData] = useState(initialState);
  const { users } = useSelector(adminPanelSelector);
  const { addUserRequest, errors, loading, dropErrors } = useUserRequests();

  const dispatch = useDispatch();

  const handleCreate = async (e) => {
    e.preventDefault();
    const newUser = await addUserRequest(formData);
    if (!newUser) return;
    dispatch({
      type: "UPDATE_USERS",
      payload: { users: [...users, newUser], user: newUser },
    });
    setFormData(initialState);
    props.createHook(false);
  };

  const updateFormField = (key, e) => {
    const newFormData = { ...formData };
    newFormData[key] = e.target.value;
    setFormData(newFormData);
  };

  const handleHide = () => {
    setFormData(initialState);
    dropErrors();
    props.createHook(false);
  };

  return (
    <Modal show={props.show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Создание нового пользователя</Modal.Title>
      </Modal.Header>

      <Form className="mb-3">
        <Modal.Body>
          <Alert show={errors.length} variant="danger">
            {errors.map((error, i) => (
              <p key={i}>{error}</p>
            ))}
          </Alert>
          {Object.entries(formData).map((key, value) => {
            key = key[0];
            if (key === "role") return null;
            const isInvalid =
              (key === "email" && !formData.email) ||
              (key === "password" && !formData.password);
            return (
              <Form.Control
                className="mb-3"
                value={formData[key]}
                onChange={(e) => updateFormField(key, e)}
                placeholder={placeholders[key]}
                key={key}
                isInvalid={isInvalid}
              />
            );
          })}
          <Form.Group>
            <Form.Label>Роль</Form.Label>
            <div>
              {["admin", "reviewer", "marker"].map((role) => (
                <Form.Check
                  inline
                  type="radio"
                  label={role}
                  name="role"
                  value={role}
                  onChange={(e) => updateFormField("role", e)}
                  checked={formData["role"] === role}
                />
              ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="success"
            disabled={loading || !formData.email || !formData.password}
            onClick={handleCreate}
          >
            Создать
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UserCreateModal;
