import { push } from "connected-react-router";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

export default function ImageNotFoundBanner() {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.router.location.pathname);
  return (
    <div className="text-center">
      <Alert variant="danger">
        <Alert.Heading>Изображение не найдено</Alert.Heading>
      </Alert>
      <Button variant="outline-success" onClick={() => dispatch(push(url))}>
        Разметить другое изображение
      </Button>
    </div>
  );
}
