import React, { useEffect } from "react";

const HotkeyManager = ({ children, bindings, disabled }) => {
  useEffect(() => {
    const handleHotkeys = (e) => {
      if (disabled) {
        return;
      }
      if (!Object.keys(bindings).includes(e.keyCode.toString())) {
        return;
      }

      const callback = bindings[e.keyCode];
      callback();
    };

    window.addEventListener("keydown", handleHotkeys);
    return () => {
      window.removeEventListener("keydown", handleHotkeys);
    };
  }, [disabled, bindings]);

  return <>{children}</>;
};

export default HotkeyManager;
