import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

export default () => {
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <Alert variant="danger">
        <Alert.Heading>Доступ запрещен</Alert.Heading>
        <hr />
        <div>Для доступа к данному ресурсу нужен аккаунт администратора</div>
      </Alert>
      <Button variant="outline-success" onClick={() => dispatch(push("/"))}>
        На главную
      </Button>
    </div>
  );
};
