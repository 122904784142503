import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Alert,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminPanelSelector } from "../../selectors";
import TaskManager from "./TaskManager";
import styles from "./user-panel.module.css";
import useUserRequests from "./useUserRequests";

const initialState = {
  email: "",
  password: "",
  last_name: "",
  first_name: "",
  middle_name: "",
  role: "",
};

const labels = {
  email: "Email",
  password: "Новый пароль",
  last_name: "Фамилия",
  first_name: "Имя",
  middle_name: "Отчество",
  role: "Роль",
};

export default function UserForm({ user, currentUser }) {
  const [formData, setFormData] = useState(Object.assign({}, initialState));
  const { users } = useSelector(adminPanelSelector);
  const { deleteUserRequest, errors, updateUserRequest, loading } =
    useUserRequests();

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(Object.assign({}, { ...initialState, role: user.role }));
  }, [user]);

  let fieldsEmpty = true;
  for (const [key] of Object.entries(formData)) {
    if (formData[key] !== "" && key !== "role") {
      fieldsEmpty = false;
      break;
    }
  }
  const canSave = !fieldsEmpty || formData["role"] !== user.role;

  const canDelete = user.id !== Number(currentUser.id);

  const saveData = async () => {
    const data = {};
    for (const [key, value] of Object.entries(formData)) {
      if (formData[key] !== "") {
        data[key] = value;
      }
    }
    const updatedUser = await updateUserRequest(user.id, data);
    if (!updatedUser) return;
    const updatedUsersList = users.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser;
      }
      return user;
    });
    dispatch({
      type: "UPDATE_USERS",
      payload: { users: updatedUsersList, user: updatedUser },
    });
  };

  const removeUser = async () => {
    const success = await deleteUserRequest(user.id);
    setShowModal(false);
    if (!success) return;
    const updatedUsersList = users.filter(
      (userFromList) => user.id !== userFromList.id
    );
    dispatch({
      type: "UPDATE_USERS",
      payload: {
        users: updatedUsersList,
        user: updatedUsersList[updatedUsersList.length - 1] || null,
      },
    });
  };

  const updateFormField = (key, e) => {
    const newFormData = { ...formData };
    newFormData[key] = e.target.value;
    setFormData(newFormData);
  };

  return (
    <Col className="mt-2 col-8 offset-2">
      <Alert show={errors.length} variant="danger">
        {errors.map((error) => (
          <p>{error}</p>
        ))}
      </Alert>
      <Form>
        {Object.keys(formData).map((key) => {
          if (key === "role") return null;
          return (
            <Form.Group key={key}>
              <Form.Label>{labels[key]}</Form.Label>
              <Form.Control
                value={formData[key]}
                onChange={(e) => updateFormField(key, e)}
                placeholder={user[key]}
              />
            </Form.Group>
          );
        })}
        <Form.Group>
          <Form.Label>{labels["role"]}</Form.Label>
          <div>
            {["admin", "reviewer", "marker"].map((role) => (
              <Form.Check
                inline
                type="radio"
                label={role}
                name="role"
                value={role}
                onChange={(e) => updateFormField("role", e)}
                checked={formData["role"] === role}
              />
            ))}
          </div>
        </Form.Group>
        <Container className="mt-1 mb-1">
          <Row>
            <Button
              className="mr-auto"
              variant="success"
              disabled={!canSave || loading}
              onClick={saveData}
            >
              Сохранить
            </Button>
            <Button
              variant="danger"
              disabled={!canDelete || loading}
              onClick={() => setShowModal(true)}
            >
              Удалить
            </Button>
          </Row>
        </Container>
      </Form>
      <Container className={styles["task-manager"]}>
        {user.role === "admin" ? null : <TaskManager user={user} />}
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>Пользователь будет удален. Вы уверены?</Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            type="submit"
            variant="danger"
            onClick={removeUser}
          >
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
