import { push } from "connected-react-router";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function NoMoreBatchesBanner() {
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <Alert variant="warning">
        <Alert.Heading>Нет новых изображений</Alert.Heading>
        <hr />
        <div>Все изображения размечены</div>
      </Alert>
      <Button
        variant="outline-success"
        onClick={() => {
          dispatch(push("/tasks"));
        }}
      >
        Перейти к выбору задания
      </Button>
    </div>
  );
}
