import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Plot from "../../../../../Utils/Plot";
import { useSelector } from "react-redux";
import { timetrackingSelector } from "../../../../selectors";
import { fillHistograms } from "./utils";
import NothingToShowBanner from "../../NothingToShowBanner";
import Divider from "./Divider";

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flex: 1,
  "& > div": {
    width: "100%",
    overflow: "auto",
  },
});

const itemColors = {}; // список цветов users/tasks

const ByDayHistogram = () => {
  const [divideBy, setDivideBy] = useState("none");
  const { timeReportData, nameDictionary } = useSelector(timetrackingSelector);
  const { dates } = timeReportData;
  if (!dates) {
    return <NothingToShowBanner />;
  }
  const {
    byTimeTasks,
    byBatchesTasks,
    byThresholdTasks,
    avgSpeedTasks,
    byTimeUsers,
    byBatchesUsers,
    byThresholdUsers,
    avgSpeedUsers,
    byTimeTotal,
    byBatchesTotal,
    byThresholdTotal,
  } = fillHistograms(dates, nameDictionary, itemColors);

  const byTime =
    divideBy === "none"
      ? byTimeTotal
      : divideBy === "users"
      ? byTimeUsers
      : byTimeTasks;
  const byBatches =
    divideBy === "none"
      ? byBatchesTotal
      : divideBy === "users"
      ? byBatchesUsers
      : byBatchesTasks;
  const avgSpeed = divideBy === "users" ? avgSpeedUsers : avgSpeedTasks;
  const byThreshold =
    divideBy === "none"
      ? byThresholdTotal
      : divideBy === "users"
      ? byThresholdUsers
      : byThresholdTasks;
  return (
    <Wrapper>
      <Divider divideBy={divideBy} handleChange={setDivideBy} />
      <>
        <Plot
          data={byTime}
          style={{ width: "100%", height: 600 }}
          layout={{
            barmode: "stack",
            boxmode: "group",
            yaxis: {
              title: { text: "Потрачено времени" },
              type: "date",
              tickformat: "%-Hч %-Mм",
            },
            xaxis: {
              tickangle: 0,
            },
          }}
        />
        <Plot
          data={byBatches}
          style={{ width: "100%", height: 600 }}
          layout={{
            barmode: "stack",
            boxmode: "group",
            yaxis: { title: { text: "Батчей" } },
            xaxis: {
              tickangle: 0,
            },
          }}
        />
        {divideBy !== "none" && (
          <Plot
            style={{ width: "100%", height: 600 }}
            data={avgSpeed}
            layout={{
              barmode: "stack",
              boxmode: "group",
              yaxis: { title: { text: "Средняя скорость, сек/батч" } },
              xaxis: {
                tickangle: 0,
              },
            }}
          />
        )}
        <Plot
          style={{ width: "100%", height: 600 }}
          data={byThreshold}
          layout={{
            barmode: "stack",
            boxmode: "group",
            yaxis: { title: { text: "Превышений порога" } },
            xaxis: {
              tickangle: 0,
            },
          }}
        />
      </>
    </Wrapper>
  );
};

export default ByDayHistogram;
