import React from "react";
import { Alert } from "react-bootstrap";

import "./styles.css";

export default (error) => (
  <div className="text-center">
    <Alert variant="danger" className="error-handler">
      <Alert.Heading>Ошибка</Alert.Heading>
      <div>
        {error ||
          "Проверьте подключение к Интернету или свяжитесь с администратором"}
      </div>
    </Alert>
  </div>
);
