import { useState } from "react";
import { Request, ValuesOf } from "../../../../utils";

type addUserData = {
  [key in ValuesOf<typeof addUserFormFields>]: string;
};

const { API_URL } = window;

const addUserFormFields = <const>[
  "email",
  "first_name",
  "last_name",
  "middle_name",
  "password",
  "role",
];

const useUserRequests = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("access_token");

  const request = new Request(setErrors, setLoading);

  const dropErrors = () => {
    setErrors([]);
  };

  const addUserRequest = (data: addUserData) => {
    return request.create({
      method: "POST",
      url: `${API_URL}/users`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const deleteUserRequest = (id: number) => {
    return request.create({
      method: "DELETE",
      url: `${API_URL}/users/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const updateUserRequest = (id: number, data: { [key: string]: string }) => {
    return request.create({
      method: "PATCH",
      url: `${API_URL}/users/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getUsersRequest = () => {
    return request.create({
      method: "GET",
      url: `${API_URL}/users`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return {
    errors,
    loading,
    addUserRequest,
    dropErrors,
    deleteUserRequest,
    updateUserRequest,
    getUsersRequest,
  };
};

export default useUserRequests;
