import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Request } from "../../../../utils";
import { adminPanelSelector } from "../../selectors";

const { API_URL } = window;

const useClassRequest = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { tasks } = useSelector(adminPanelSelector);

  const request = new Request(setErrors, setLoading);

  const token = localStorage.getItem("access_token");

  const dropErrors = () => setErrors([]);

  const addClass = (task_id: number, class_name: string) => {
    return request.create({
      url: `${API_URL}/tasks/${task_id}/classes`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: { class_name },
    });
  };

  const patchClass = (
    task_id: number,
    markup_label: string,
    class_name: string
  ) => {
    return request.create({
      url: `${API_URL}/tasks/${task_id}/classes/${markup_label}`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${token}` },
      data: { class_name },
    });
  };

  const deleteClass = (task_id: number, markup_label: string) => {
    return request.create({
      url: `${API_URL}/tasks/${task_id}/classes/${markup_label}`,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const updateTasks = (updatedTask: any) => {
    const updatedTasksList = tasks.map((task: any) => {
      if (task.id === updatedTask.id) return updatedTask;
      return task;
    });
    dispatch({
      type: "UPDATE_TASKS",
      payload: {
        tasks: updatedTasksList,
        task: updatedTask,
      },
    });
  };

  return {
    patchClass,
    deleteClass,
    addClass,
    updateTasks,
    dropErrors,
    errors,
    loading,
  };
};

export default useClassRequest;
