import { handleActions } from "redux-actions";

const defaultState = {
  type: null,
  markupType: null,
  batchId: null,
  batchImages: null,
  settings: null,
};

export default handleActions(
  {
    OPEN_TASK: (state) => {
      return {
        ...state,
        markupType: null,
        batchId: null,
        batchImages: null,
        settings: null,
      };
    },
    INIT_TASK: (state, action) => {
      return {
        ...state,
        markupType: action.markupType,
        settings: action.settings,
      };
    },
    DROP_TASK: () => {
      return {
        markupType: null,
        batchId: null,
        batchImages: null,
        settings: null,
      };
    },
    SET_MARKUP_CONTEXT: (state, action) => {
      return {
        ...state,
        batchId: action.batchId,
        batchImages: action.images,
      };
    },
    SET_MARKUP: (state, action) => {
      const images = state.batchImages.slice();
      const image = images.filter((img) => img.id === action.imageId)[0];
      image.markup = action.markup;
      return {
        ...state,
        batchImages: images,
      };
    },
    LOAD_NEW_BATCH: (state) => {
      return {
        ...state,
        batchId: null,
        batchImages: null,
      };
    },
    GO_BACK: (state, action) => {
      return {
        ...state,
        batchId: action.batchId,
        batchImages: null,
      };
    },
    INIT_MARKUP: (state, action) => {
      return {
        ...state,
        batchImages: action.initImages,
      };
    },
  },
  defaultState
);
