import React, { useState } from "react";
import { ClassInput } from "./TaskClassesCreateList";
import { Button, Col, Row, Spinner, Alert } from "react-bootstrap";
import useClassRequest from "./useClassRequest";

const TaskClass = ({ cls, classes, task_id }) => {
  const [className, setClassName] = useState("");
  const { patchClass, deleteClass, updateTasks, loading, dropErrors, errors } =
    useClassRequest();
  const handleClassInput = (e) => {
    setClassName(e.target.value);
  };

  const classNameChanged = className && className !== cls.markup_label;

  const classesNames = classes.reduce((acc, item) => {
    acc[item.markup_label] = true;
    return acc;
  }, {});

  const handlePatch = async () => {
    const task = await patchClass(task_id, cls.markup_label, className);
    if (!task) return;
    updateTasks(task);
    setClassName("");
  };

  const handleDeleteRequest = async () => {
    const task = await deleteClass(task_id, cls.markup_label);
    if (!task) return;
    updateTasks(task);
  };

  return (
    <>
      <Alert
        className="mt-3 mb-1"
        show={errors.length}
        variant="danger"
        onClose={() => dropErrors()}
        dismissible
      >
        {errors.map((error, i) => (
          <p key={i}>{error}</p>
        ))}
      </Alert>
      <Row className="mt-1">
        <Col className="col-xs-5 col-sm-5 col-md-5">
          <ClassInput
            disabled={false}
            value={className}
            placeholder={cls.markup_label}
            handleClassInput={handleClassInput}
          />
        </Col>
        {classNameChanged ? (
          <Col className="col-xs-2 col-sm-2 col-md-2">
            <Button
              block
              variant="success"
              onClick={handlePatch}
              disabled={classesNames[className]}
            >
              &#10004;
            </Button>
          </Col>
        ) : (
          <Col className="col-xs-2 col-sm-2 col-md-2">
            <Button block variant="danger" onClick={handleDeleteRequest}>
              -
            </Button>
          </Col>
        )}
        {loading && <Spinner animation="border" role="status" />}
      </Row>
    </>
  );
};

export default TaskClass;
