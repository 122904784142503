import axios from "axios";
import { push } from "connected-react-router";
import store from "./store";
import { buildUrl } from "./utils";

const { API_URL } = window;

export const errorScopeManager = async (f, expectedCodes = []) => {
  try {
    const res = await f();
    return res;
  } catch (e) {
    if (typeof e.response !== "undefined") {
      const status = e.response.data.error_code;
      if (expectedCodes.includes(status)) {
        return {
          errorCode: e.response.status,
          msg: e.response.data.error_code,
        };
      }
    }
    store.dispatch(push("/network_error"));
    return {};
  }
};

const login = (email, password, expectedCodes = []) =>
  errorScopeManager(async () => {
    const res = await axios.post(
      `${API_URL}/login`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data.data;
  }, expectedCodes);

const getUserById = (userId) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const res = await axios.get(`${API_URL}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  });

const getCurrentUser = () => {
  const userId = localStorage.getItem("user_id");
  return getUserById(userId);
};

const getMarkup = async (taskId) => {
  const token = localStorage.getItem("access_token");
  const res = await axios.get(`${API_URL}/tasks/${taskId}/markup`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const getTask = (taskId) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const res = await axios.get(`${API_URL}/tasks/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  });

const assignUser = (taskId, userId) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const res = await axios.post(
      `${API_URL}/tasks/${taskId}/assign_user`,
      {
        user_id: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.data;
  });

const dissociateUser = (taskId, userId) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const res = await axios.post(
      `${API_URL}/tasks/${taskId}/dissociate_user`,
      {
        user_id: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.data;
  });

const getUsers = (taskId = null) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    let url = `${API_URL}/users`;
    if (taskId !== null) {
      url += `?task_id=${taskId}`;
    }
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  });

const getBatch = async (batchId) => {
  const token = localStorage.getItem("access_token");
  const res = await axios.get(`${API_URL}/batches/${batchId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

const updateBatch = (batchId, state) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    await axios.patch(
      `${API_URL}/batches/${batchId}`,
      {
        state: state,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return {};
  });

const getBatches = (query, expectedCodes = []) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const encodedQuery = Object.entries(query).reduce((acc, [key, val]) => {
      acc[key] = encodeURIComponent(val);
      return acc;
    }, {});
    const url = buildUrl(`${API_URL}/batches`, {
      ...encodedQuery,
      per_page: 10,
    });
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  }, expectedCodes);

const getBatchToMarkup = async (taskId) => {
  const token = localStorage.getItem("access_token");
  const res = await axios.get(`${API_URL}/tasks/${taskId}/batch_to_markup`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

const getTaskStatus = (taskId) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const res = await axios.get(`${API_URL}/tasks/${taskId}/status`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  });

const getImages = async (batchId = null) => {
  const token = localStorage.getItem("access_token");
  let url = `${API_URL}/images?per_page=1000`;
  if (batchId !== null) {
    url += `&batch_id=${batchId}`;
  }
  const res = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

const updateImage = (imageId, markup) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    await axios.patch(
      `${API_URL}/images/${imageId}`,
      {
        markup: markup,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return {};
  });

const loadImage = (imageId) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = `${API_URL}/images/${imageId}/image`;
  });
};

const getUserTimetracking = (userId, query, expectedCodes = []) =>
  errorScopeManager(async () => {
    const token = localStorage.getItem("access_token");
    const url = buildUrl(`${API_URL}/users/${userId}/timetracking`, {
      ...query,
    });
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data;
  }, expectedCodes);

export default {
  login,
  getUserById,
  getCurrentUser,
  getMarkup,
  getTask,
  assignUser,
  dissociateUser,
  getUsers,
  getBatch,
  updateBatch,
  getBatches,
  getBatchToMarkup,
  getTaskStatus,
  getImages,
  updateImage,
  loadImage,
  getUserTimetracking,
};
