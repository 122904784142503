import React, { useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { push } from "connected-react-router";
import store from "../../../../store";

const { API_URL } = window;

const BatchState = {
  ALL: {
    queryParam: null,
    label: "Все",
  },
  NOT_ASSIGNED: {
    queryParam: "not_assigned",
    label: "Не назначенные",
  },
  MARKING: {
    queryParam: "marking",
    label: "Размечаемые",
  },
  SKIPPED: {
    queryParam: "skipped",
    label: "Пропущенные",
  },
  DONE: {
    queryParam: "done",
    label: "Готовые",
  },
};

function download(content, fileName, contentType) {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export default function TaskMarkupDownloader(props) {
  const [showModal, setShowModal] = useState(false);
  const [batchState, setBatchState] = useState(BatchState.ALL.queryParam);
  const [showLoader, setShowLoader] = useState(false);

  const downloadMarkup = async (batchState, e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    let attachUrl = `${API_URL}/tasks/${props.taskId}/markup`;
    if (batchState !== null) {
      attachUrl += `?batch_state=${batchState}`;
    }
    setShowLoader(true);
    try {
      const { data } = await axios.get(attachUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      download(JSON.stringify(data), "annotation.json", "json");
    } catch (e) {
      store.dispatch(push("/network_error"));
    } finally {
      setShowLoader(false);
    }
  };

  let isFirstListItem = true;
  return (
    <Container className="text-center">
      <Button variant="success" onClick={() => setShowModal(true)}>
        Скачать разметку
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>Выберите состояние батчей</Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Control
              as="select"
              className="mb-3"
              onChange={(e) => setBatchState(e.target.value)}
            >
              {Object.entries(BatchState).map((key, value) => {
                const [, stateInfo] = key;
                const { queryParam, label } = stateInfo;
                if (isFirstListItem) {
                  isFirstListItem = false;
                  return (
                    <option key={label} value={queryParam} selected>
                      {label}
                    </option>
                  );
                } else {
                  return (
                    <option key={label} value={queryParam}>
                      {label}
                    </option>
                  );
                }
              })}
            </Form.Control>
            {showLoader ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <Button
                type="submit"
                variant="success"
                onClick={(e) => downloadMarkup(batchState, e)}
              >
                Cкачать
              </Button>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </Container>
  );
}
