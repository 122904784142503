import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";

import MarkupApi from "../../markupApi";

import styles from "./task-progress.module.css";

export default function TaskProgress(props) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const loadStatus = async () => {
      const newStatus = await MarkupApi.getTaskStatus(props.taskId);
      setStatus(newStatus);
    };

    loadStatus();
  }, [props]);

  let body = null;
  let percentage = null;
  let variant = null;
  let classes = ["text-right", styles.progress];

  if (status !== null) {
    body = `${status.done_batches} / ${status.total_batches}`;
    if (status.total_batches === 0) {
      percentage = 0;
    } else {
      percentage = (status.done_batches / status.total_batches) * 100;
    }
    percentage = Math.floor(percentage);
    switch (percentage) {
      case 0:
        variant = "danger";
        break;
      case 100:
        variant = "success";
        break;
      default:
        variant = "warning";
        break;
    }

    classes = ["text-right", styles["loaded-progress"]];
  }

  return (
    <Container className={classes.join(" ")}>
      {body !== null ? (
        <Row>
          <Col className="col-10">{body}</Col>
          <Col className="col-2">
            <Badge variant={variant}>{`${percentage}%`}</Badge>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
