// file was transformed from js --> ts
// functions that have parameters with type "any" were created before the transformation

import axios from "axios";
import { Dispatch, SetStateAction } from "react";

declare global {
  interface Window {
    API_URL: string;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
  }
}

export type ValuesOf<T extends readonly any[]> = T[number];

export const filterNullValues = (obj: any) => {
  const res = Object.assign({}, obj);
  for (const k of Object.keys(res)) {
    if (res[k] === null) {
      delete res[k];
    }
  }
  return res;
};

export const buildUrl = (url: any, jsonQuery: any) => {
  const queryParams = Object.keys(jsonQuery).map((p) => `${p}=${jsonQuery[p]}`);
  return url + "?" + queryParams.join("&");
};

export const toUnixTime = (date: any) => Math.floor(date / 1000);

export const fromUnixTime = (timestamp: any) => timestamp * 1000;

export const toDateString = (dateString: any) => {
  return dateString.toISOString().slice(0, 10);
};

export const buildFieldsErrorList = (error: { [key: string]: string[] }) => {
  const errorsArray = Object.entries(error);
  const errorStrings = errorsArray.reduce((acc, val) => {
    const [field, fieldRelatedErrors] = val;
    let str = field + ": ";
    fieldRelatedErrors.forEach((error) => {
      str += error + " ";
    });
    acc.push(str);
    return acc;
  }, [] as string[]);
  return errorStrings;
};

export class Request {
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  create: (axiosParams: any) => boolean | any;
  constructor(
    setErrors: Dispatch<SetStateAction<string[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) {
    this.setErrors = setErrors;
    this.setLoading = setLoading;
    this.create = async (axiosParams) => {
      try {
        setErrors([]);
        setLoading(true);
        const { data } = await axios(axiosParams);
        return data?.data || true;
      } catch (e: any) {
        if (e.response.data.error) {
          const fieldsErrors = buildFieldsErrorList(e.response.data.error);
          setErrors(fieldsErrors);
          return false;
        }
        setErrors([e.response.data.message]);
        return false;
      } finally {
        setLoading(false);
      }
    };
  }
}
