import React, { useState, useEffect } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import styles from "./style.module.css";

const TimetrackingUserSelector = ({
  selectedUsers,
  assignedUsers,
  handleIdChange,
}) => {
  const [selected, setSelected] = useState(selectedUsers);

  useEffect(() => {
    setSelected(selectedUsers);
  }, [selectedUsers]);
  return (
    <Autocomplete
      value={selected}
      className={styles["multiple-select"]}
      limitTags={2}
      multiple
      id="checkboxes-tags-demo"
      options={assignedUsers}
      disableCloseOnSelect
      onChange={(e, value) => setSelected(value)}
      onClose={(e) => handleIdChange(e, selected)}
      getOptionLabel={(user) => user.email}
      renderOption={(props, user, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {user.email}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Пользователи"
          placeholder="Введите пользователя"
        />
      )}
    />
  );
};

export default TimetrackingUserSelector;
