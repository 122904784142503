import React from "react";
import { Pagination } from "react-bootstrap";
import DateSelector from "./DateSelector/component";

const PaginatedDateSelector = ({ date, handleDateChange, disabled }) => {
  const handlePaginationClick = (dayBias) => {
    let day = date.getDate();
    day += dayBias;
    const newDate = new Date(date);
    newDate.setDate(day);
    handleDateChange(newDate);
  };

  return (
    <div>
      <Pagination width="100%">
        <Pagination.Prev
          onClick={() => handlePaginationClick(-1)}
          disabled={disabled}
        />
        <DateSelector
          date={date}
          handleDateChange={handleDateChange}
          disabled={disabled}
        />
        <Pagination.Next
          onClick={() => handlePaginationClick(1)}
          disabled={disabled}
        />
      </Pagination>
    </div>
  );
};

export default PaginatedDateSelector;
