import React, { useState } from "react";
import { ListGroup, Pagination } from "react-bootstrap";

const PaginatedList = ({
  items,
  page,
  totalPages,
  renderCallback,
  handlePageChange,
}) => {
  const [edit, setEdit] = useState(false);
  if (totalPages === 0) {
    return null;
  }

  return (
    <div>
      <ListGroup>{items.map((i) => renderCallback(i))}</ListGroup>
      <Pagination className="justify-content-center mt-3">
        <Pagination.Prev
          disabled={page === 0}
          onClick={() => handlePageChange(page - 1)}
        />
        <Pagination.Item
          onClick={() => {
            setEdit(true);
          }}
        >
          {edit && (
            <input
              type="number"
              min={1}
              max={totalPages}
              onBlur={(e) => {
                const newPage = parseInt(e.currentTarget.value);
                if (
                  newPage <= totalPages &&
                  newPage > 0 &&
                  newPage - 1 !== page
                ) {
                  handlePageChange(newPage - 1);
                }
                setEdit(false);
              }}
              onKeyDown={(e) => {
                // on enter
                if (e.keyCode === 13) {
                  e.currentTarget.blur();
                }
              }}
            />
          )}
          {!edit && page + 1}
          {`/${totalPages}`}
        </Pagination.Item>
        <Pagination.Next
          disabled={page === totalPages - 1}
          onClick={() => handlePageChange(page + 1)}
        />
      </Pagination>
    </div>
  );
};

export default PaginatedList;
