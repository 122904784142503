import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTimeReportData,
  setAvailableItems,
  changeTimetrackingDate,
  dropTimetracking,
} from "../../../../../actions/timetracking";
import axios from "axios";
import { timetrackingSelector } from "../../../selectors";
import { getTasks, getDates, getUsers, formatDate } from "./utils";
import { adminPanelSelector } from "../../../selectors";

const { API_URL } = window;

const useTimetracking = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const { users: allUsers, tasks: allTasks } = useSelector(adminPanelSelector);
  const allUsersIds = [...Object.values(allUsers).map((user) => user.id)];
  const {
    selectedUsers,
    selectedTasks,
    availableTasks,
    availableUsers,
    availableDates,
    from,
    to,
    timeThreshold,
  } = useSelector(timetrackingSelector);
  const token = localStorage.getItem("access_token");

  const [disableGetReport, setDisableGetReport] = useState(true);

  const selectedTasksIds = selectedTasks.reduce(
    (acc, task) => (acc = [...acc, task.id]),
    []
  );

  const selectedUsersIds = selectedUsers.reduce(
    (acc, user) => (acc = [...acc, user.id]),
    []
  );

  let assignedTasks = [];
  allTasks.forEach((taskObj) => {
    availableTasks.forEach((task) => {
      if (+taskObj.id === +task.id) {
        assignedTasks.push(taskObj);
      }
    });
  });

  let assignedUsers = allUsers.filter((user) =>
    availableUsers.includes(user.id)
  );

  const handleDateChange = (date) => {
    const { from, to } = formatDate(date);
    dispatch(changeTimetrackingDate({ from, to }));
  };

  const makeRequest = async () => {
    setShowLoader(true);
    const RequestParams = {};
    let paramsSet = false; // выбран хотя бы 1 параметр
    // не передавать user_ids, если не выбран ни один user
    if (selectedUsersIds.length) {
      RequestParams.user_ids = selectedUsersIds.join(",");
      paramsSet = true;
    }
    if (selectedTasksIds.length) {
      RequestParams.task_ids = selectedTasksIds.join(",");
      paramsSet = true;
    }
    if (from && to) {
      RequestParams.from = from;
      RequestParams.to = to;
      paramsSet = true;
    }
    // если ни одного параметра не выбрано, установить все значения доступными для выбора
    if (!paramsSet) {
      dispatch(
        setAvailableItems({
          availableUsers: allUsersIds,
          availableTasks: allTasks,
          availableDates: [],
        })
      );
      setShowLoader(false);
      return;
    }
    const res = await axios.get(`${API_URL}/participation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: RequestParams,
    });
    const tasks = getTasks(res.data.data.dates);
    const dates = getDates(res.data.data.dates);
    const users = getUsers(res.data.data.dates);

    // enable get report button if all required fields are filled
    setDisableGetReport(
      !selectedTasksIds.length || !selectedUsersIds.length || !from || !to
    );

    dispatch(
      setAvailableItems({
        availableUsers: users,
        availableTasks: tasks,
        availableDates: dates,
      })
    );
    setShowLoader(false);
  };

  const dropFilters = () => {
    dispatch(dropTimetracking());
    setDisableGetReport(true);
  };

  const getTimeReport = async () => {
    setShowLoader(true);
    const RequestParams = {
      task_ids: selectedTasksIds.join(","),
      user_ids: selectedUsersIds.join(","),
      from: from,
      to: to,
    };
    if (timeThreshold) {
      RequestParams.threshold = `0h${Math.floor(timeThreshold / 60)}m${
        timeThreshold % 60
      }s`;
    }
    try {
      const res = await axios.get(`${API_URL}/time_report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: RequestParams,
      });
      dispatch(setTimeReportData(res.data.data));
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    makeRequest();
  }, [selectedUsers, from, to, selectedTasks]);

  return {
    dispatch,
    selectedTasks,
    assignedTasks,
    selectedUsers,
    assignedUsers,
    handleDateChange,
    availableDates,
    dropFilters,
    getTimeReport,
    disableGetReport,
    showLoader,
  };
};

export default useTimetracking;
