import React from "react";
import BatchList from "./BatchList/component";
import MarkupQuerySelector from "./MarkupQuerySelector/component";

const SideBar = () => (
  <div className="mt-2">
    <MarkupQuerySelector />
    <BatchList />
  </div>
);

export default SideBar;
