import React from "react";
import {
  NavLink as NavLinkDefault,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { styled } from "@mui/material/styles";

const NavLink = styled(NavLinkDefault)(({ active }) => ({
  color: active ? "#fff" : "#007bff",
  backgroundColor: active ? "#007bff" : "#fff",
  padding: "5px 10px",
  borderRadius: 5,
  marginRight: 5,
  "&:hover": {
    textDecoration: "none",
    backgroundColor: "#CCE5FF",
  },
}));

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HistogramSwitcher = () => {
  let { url } = useRouteMatch();
  const query = useQuery();
  const name = query.get("view");
  return (
    <div style={{ padding: "10px 0px" }}>
      <NavLink active={!name} to={`${url}?tab=timetracking`}>
        В общем
      </NavLink>
      <NavLink
        active={name === "graph"}
        to={`${url}?tab=timetracking&view=graph`}
      >
        По дням
      </NavLink>
    </div>
  );
};

export default HistogramSwitcher;
