/* NOTE: no refetching data on deletes and e.t.c.
 *  used to prevent useless fetches and client-side data aggregation
 *     - assignUser
 *     - dissUser
 *
 *  If there are more than one admin, it may cause invalid info in admin panel!!!
 * */

import { batch } from "react-redux";
import { createAction } from "redux-actions";
import MarkupApi from "../markupApi";
import { filterNullValues, toUnixTime, toDateString } from "../utils";

export const lockFetch = createAction("LOCK_ADMIN_FETCH");

export const unlockFetch = createAction("UNLOCK_ADMIN_FETCH");

export const assignUser = (user, taskId) => {
  return async (dispatch) => {
    await MarkupApi.assignUser(taskId, user.id);
    dispatch({
      type: "ASSIGN_USER",
      payload: { userId: user.id, taskId },
    });
  };
};

export const dissUser = (user, taskId) => {
  return async (dispatch) => {
    await MarkupApi.dissociateUser(taskId, user.id);
    dispatch({
      type: "DISSOCIATE_USER",
      payload: { userId: user.id, taskId },
    });
  };
};

export const setUser = createAction("SET_USER");

export const setTask = createAction("SET_TASK");

export const changeQueryUserId = createAction("CHANGE_QUERY_USER_ID");

export const changeQueryTaskId = createAction("CHANGE_QUERY_TASK_ID");

export const changeQueryBatchMarkedStatus = createAction(
  "CHANGE_QUERY_BATCH_MARKED_STATUS"
);

export const changeQueryBatchClass = createAction("CHANGE_QUERY_BATCH_CLASS");

export const changeQueryDate = createAction("CHANGE_QUERY_DATE");
export const toggleDateUsage = createAction("TOGGLE_DATE_USAGE");

export const changeQueryBatchId = createAction("CHANGE_QUERY_BATCH_ID");

export const dropQuery = createAction("DROP_QUERY");

export const changeTimetrackingUserId = createAction(
  "CHANGE_TIMETRACKING_USER_ID"
);

export const changeTimetrackingTaskId = createAction(
  "CHANGE_TIMETRACKING_TASK_ID"
);

export const changeTimetrackingFromDate = createAction(
  "CHANGE_TIMETRACKING_FROM_DATE"
);

export const changeTimetrackingToDate = createAction(
  "CHANGE_TIMETRACKING_TO_DATE"
);

export const changeTimetrackingThreshold = createAction(
  "CHANGE_TIMETRACKING_THRESHOLD"
);

export const dropTimetrackingQuery = createAction("DROP_TIMETRACKING_QUERY");

export const deleteMarkup = () => {
  return async (dispatch, getState) => {
    const { images, batches, isFetching } = getState().adminPanelReducer;
    if (isFetching || images === null) {
      return;
    }
    dispatch(lockFetch());
    const { batchId, page } = getState().adminPanelReducer.markupQuery;
    for (const img of images) {
      await MarkupApi.updateImage(img.id, null);
    }
    await MarkupApi.updateBatch(batchId, "not_assigned");
    const batchIndex = batches.findIndex((b) => b.id === batchId);
    dispatch(loadPage(page, batchIndex));
  };
};

const loadPageFetch = async (
  page,
  userId,
  taskId,
  date,
  useDate,
  marked = null,
  classname = null
) => {
  const query = filterNullValues({
    page,
    user_id: userId,
    task_id: taskId,
    markup_date: useDate ? toDateString(date) : null,
    marked: marked,
    class_name: classname,
    states: useDate ? null : ["done"],
  });

  // TODO: check if error really occures
  let resp = await MarkupApi.getBatches(query, ["common.PaginationError"]);
  if ("errorCode" in resp) {
    if (query.page !== 0) {
      query.page--;
    }
    resp = await MarkupApi.getBatches(query, ["common.PaginationError"]);
  }
  return resp;
};

export const loadPage = (page, batchIndex = 0) => {
  return async (dispatch, getState) => {
    const { userId, taskId, date, useDate, marked, classname } =
      getState().adminPanelReducer.markupQuery;
    const resp = await loadPageFetch(
      page,
      userId,
      taskId,
      date,
      useDate,
      marked,
      classname
    );
    const { data, pagination } = resp;
    if (batchIndex >= data.length) {
      batchIndex = data.length - 1;
    }
    const batchId = batchIndex > -1 ? data[batchIndex].id : null;

    batch(() => {
      dispatch({
        type: "SET_PAGINATION_DATA",
        payload: {
          page: pagination.page,
          totalPages: pagination.total_pages,
          batches: data,
          batchId,
        },
      });
      dispatch(unlockFetch());
    });
  };
};

export const getBatchImages = (batchId) => {
  return async (dispatch) => {
    if (batchId === null) {
      return;
    }
    const images = await MarkupApi.getImages(batchId);
    dispatch({
      type: "ADD_BATCH_IMAGES",
      payload: {
        images,
      },
    });
  };
};

const calcPage = (batches, page, prevId, bias) => {
  const newBatchIndex = batches.findIndex((b) => b.id === prevId) + bias;
  if (newBatchIndex < 0) {
    page--;
  } else if (newBatchIndex >= batches.length) {
    page++;
  }
  return page;
};

const selectBiasedBatch = (bias) => {
  return async (dispatch, getState) => {
    const { batches, markupQuery } = getState().adminPanelReducer;
    const { page, batchId, totalPages } = markupQuery;
    const newPage = calcPage(batches, page, batchId, bias);
    if (newPage < 0 || newPage >= totalPages) {
      return;
    }

    if (newPage !== page) {
      const { userId, taskId, date, marked, useDate, classname } = markupQuery;
      const { data, pagination } = await loadPageFetch(
        newPage,
        userId,
        taskId,
        date,
        useDate,
        marked,
        classname
      );
      const newBatchIndex = newPage < page ? data.length - 1 : 0;

      dispatch({
        type: "SET_PAGINATION_DATA",
        payload: {
          page: pagination.page,
          totalPages: pagination.total_pages,
          batches: data,
          batchId: data[newBatchIndex].id,
        },
      });
    } else {
      const newBatchIndex = batches.findIndex((b) => b.id === batchId) + bias;
      dispatch({
        type: "CHANGE_QUERY_BATCH_ID",
        payload: batches[newBatchIndex].id,
      });
    }
  };
};

export const selectPrevBatch = () => {
  return selectBiasedBatch(-1);
};

export const selectNextBatch = () => {
  return selectBiasedBatch(1);
};

const setTimetrackingData = createAction("SET_TIMETRACKING_DATA");

const roundDateToMidnight = (date) =>
  new Date(Math.floor(date / 86400000) * 86400000);

export const loadAnalytics = () => {
  return async (dispatch, getState) => {
    let {
      userId,
      taskId,
      from,
      to: viewTo,
      timeThreshold,
    } = getState().adminPanelReducer.timetrackingQuery;

    let to = new Date(viewTo);
    to.setDate(to.getDate() + 1);
    from = roundDateToMidnight(from);
    to = roundDateToMidnight(to);

    const query = filterNullValues({
      task_id: taskId,
      from: toUnixTime(from),
      to: toUnixTime(to),
      time_threshold: timeThreshold,
    });

    const timetrackingData = await MarkupApi.getUserTimetracking(userId, query);
    dispatch(setTimetrackingData(timetrackingData));
  };
};
