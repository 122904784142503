import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import styles from "./multi-binary-task.module.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const { API_URL } = window;
export default function MultiBinaryTaskBatch({ batchImages }) {
  const dispatch = useDispatch();

  const handleClick = (imgId) => {
    const img = batchImages.filter((i) => i.id === imgId)[0];
    const prevMarkup = img.markup;
    const newMarkup = !prevMarkup.marked;

    const markup = {
      marked: newMarkup,
    };
    dispatch({
      type: "SET_MARKUP",
      imageId: imgId,
      markup: markup,
    });
  };

  const imageHints = ["Q", "W", "E", "A", "S", "D", "Z", "X", "C"];

  const images = batchImages.slice();

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    image.hint = imageHints[i];
    image.styleCls =
      image.markup !== null && image.markup.marked
        ? styles["selected-image"]
        : styles.image;
  }
  return (
    <Container className="task-container text-center">
      <Container className={styles["batch-container"]}>
        <Row>
          {images.map((img) => {
            const imgClasses = [img.styleCls, styles["batch-image"]];
            const containerClasses = [
              "col-xs-4",
              "col-sm-4",
              "col-md-4",
              styles["batch-image-container"],
            ];
            return (
              <div
                key={img.id}
                className={containerClasses.join(" ")}
                onClick={() => handleClick(img.id)}
              >
                <div className={styles["image-hint"]}>{img.hint}</div>
                <TransformWrapper panning={{ disabled: true }}>
                  <TransformComponent>
                    <Image
                      className={imgClasses.join(" ")}
                      fluid
                      rounded
                      src={`${API_URL}/images/${img.id}/image`}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
}
