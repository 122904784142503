import React from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import TaskProgress from "./TaskProgress";

export default function TaskList({ tasks, callbackHook }) {
  return (
    <Container className="mt-3">
      <ListGroup>
        {tasks !== null
          ? tasks.reverse().map((t) => {
              return (
                <ListGroup.Item onClick={() => callbackHook(t.id)} key={t.id}>
                  <Row className="align-items-center">
                    <Col>
                      <Container>
                        <b>id:</b> {t.id}
                        <br />
                        <b>Name:</b> {t.name}
                      </Container>
                    </Col>
                    <Col>
                      <TaskProgress taskId={t.id} />
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })
          : null}
      </ListGroup>
    </Container>
  );
}
