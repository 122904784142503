import React from "react";
import { Row } from "react-bootstrap";
import ItemsList from "./ItemsList";

import "./style.css";

const ItemsManager = ({ itemKey, assignedProps, dissociatedProps }) => (
  <Row>
    <ItemsList itemKey={itemKey} {...dissociatedProps} />
    <ItemsList itemKey={itemKey} {...assignedProps} />
  </Row>
);

export default ItemsManager;
