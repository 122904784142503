import React, { useState, useEffect } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import styles from "./style.module.css";

const TimetrackingTaskSelector = ({
  selectedTasks,
  assignedTasks,
  handleIdChange,
}) => {
  const [selected, setSelected] = useState(selectedTasks);

  const sortedTasks = assignedTasks.sort((a, b) => b.id - a.id);

  useEffect(() => {
    setSelected(selectedTasks);
  }, [selectedTasks]);
  return (
    <Autocomplete
      multiple
      value={selected}
      limitTags={2}
      className={styles["multiple-select"]}
      id="checkboxes-tags-demo"
      options={sortedTasks}
      disableCloseOnSelect
      onChange={(e, value) => setSelected(value)}
      onClose={(e) => handleIdChange(e, selected)}
      getOptionLabel={(task) => task.name}
      renderOption={(props, task, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          <div>
            <b>id: </b>
            {task.id}
            <br />
            <b>Name: </b>
            {task.name}
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Задачи" placeholder="Введите задачу" />
      )}
    />
  );
};

export default TimetrackingTaskSelector;
